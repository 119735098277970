<template>
  <div>
    <div class="columns">
      <div :class="{'is-disabled': !guild.isAdmin}" class="column is-half">
        <div class="card tile is-child has-shadow">
          <div class="card-header-title">
            <p class="title is-4 has-text-white">Default Moderation Options</p>
          </div>
          <div class="card-content">
            <b-field class="hovermessagefeild" :message="'Current Status: ' + (guild.guildDB.data.modEnable ? 'Enabled' : 'Disabled') " label="Moderation Status" for="modStatus">
                <b-button id="modStatus" @click="modStatusUpdate" :key="guild.guildDB.data.modEnable" v-bind:type="{'is-danger': (guild.guildDB.data.modEnable ? true : false), 'is-success': (!guild.guildDB.data.modEnable ? true : false)}" v-bind:label="(guild.guildDB.data.modEnable ? 'Disable Moderation': 'Enable Moderation ')" expanded></b-button>
            </b-field>
            <b-field label="Mute Role" for="muterole">
              <v-select appendToBody :selectOnTab="true" placeholder="Search or Select a mute role!" :calculatePosition="vselectfixer" @input="muteRoleUpdate" :disabled="muteRoleDisabled" autocomplete :searchable="true" required :clearable="false" :reduce="item => item.id" :options="(guild.bot.data.roles ? guild.bot.data.roles.filter(g => g.managed === false && g.name !== '@everyone') : [])" :value="(guild.bot.data.roles.find(g => g.id === guild.guildDB.data.muteroleID) ? guild.bot.data.roles.find(g => g.id === guild.guildDB.data.muteroleID) : null)" :getOptionLabel="option => option.name" label="Mute Role">
                <template slot="option" slot-scope="option" style="width: 100%">
                  <b-tooltip :label="`ID: ${option.id}`" style="width: 100%" position="is-bottom" multilined>
                    <div class="" style="padding-right: 0px !important;color:white!important; padding-top: 10px; padding-bottom: 10px; width:100%">
                      <span class="Nunito" :style="`color: #${option.color.toString(16).padStart(6, '0')}`">{{ option.name }}</span> <b-tag class="Nunito" v-if="option.isAdmin" rounded type="is-danger">Admin</b-tag> <b-tag class="Nunito" v-if="option.isManager" rounded type="is-warning">Manage</b-tag>
                    </div>
                  </b-tooltip>
                </template>
              </v-select>
            </b-field>
            <b-field label="Filter Bypass Roles" for="filterbypassRoleUpdate">
              <b-tooltip type="is-warning" label="Deleted roles will be filtered out!" style="width: 100%">
                <v-select style="width: 100%;" appendToBody :selectOnTab="true" placeholder="Search or Select a bypass role!" :calculatePosition="vselectfixer" @input="filterbypassRoleUpdate" :disabled="filterBypassDisabled" autocomplete :searchable="true" :multiple="true" required :clearable="true" :reduce="item => item.id" :options="(guild.bot.data.roles ? guild.bot.data.roles.filter(g => g.managed === false && g.name !== '@everyone') : [])" :value="guild.guildDB.data.swearBypassRole" :getOptionLabel="option => (option ? option.name : 'MISSING')" label="Filter Bypass Role">
                  <template slot="option" slot-scope="option" style="width: 100%">
                    <b-tooltip :label="`ID: ${option.id}`" style="width: 100%" position="is-bottom" multilined>
                      <div class="" style="padding-right: 0px !important;color:white!important; padding-top: 10px; padding-bottom: 10px; width:100%">
                        <span class="Nunito" :style="`color: #${option.color.toString(16).padStart(6, '0')}`">{{ option.name }}</span> <b-tag class="Nunito" v-if="option.isAdmin" rounded type="is-danger">Admin</b-tag> <b-tag class="Nunito" v-if="option.isManager" rounded type="is-warning">Manage</b-tag>
                      </div>
                    </b-tooltip>
                  </template>
                </v-select>
              </b-tooltip>
            </b-field>
          </div>
        </div>
      </div>
      <div :class="{'is-disabled': !guild.isAdmin}" class="column is-half">
        <div class="card tile is-child has-shadow">
          <div class="card-header-title">
            <p class="title is-4 has-text-white">Filter Options</p>
          </div>
          <div class="card-content">
            <b-field label="Filter list" for="filterlist">
              <b-input id="filterlist" @focusout.native="undbindELTarJquery" @focus="guildfilterlist" custom-class="input-default" :value="guild.guildDB.data.swearData.words.join(' ')" type="textarea"></b-input>
            </b-field>
            <b-field class="hovermessagefeild" :message="'Current Status: ' + (guild.guildDB.data.swearEnable ? 'Enabled' : 'Disabled') " label="Filter Status" for="filterStatus">
                <b-button id="filterStatus" @click="filterStatusUpdate" :key="guild.guildDB.data.swearEnable" v-bind:type="{'is-danger': (guild.guildDB.data.swearEnable ? true : false), 'is-success': (!guild.guildDB.data.swearEnable ? true : false)}" v-bind:label="(guild.guildDB.data.swearEnable ? 'Disable Filter': 'Enable Filter')" expanded></b-button>
            </b-field>
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div :class="{'is-disabled': !guild.isAdmin}" class="column is-half">
        <div class="card tile is-child has-shadow">
          <div class="card-header-title">
            <p class="title is-4 has-text-white">Member Join Protection:</p>
          </div>
          <div class="card-content">
            <b-field label="Min Required Age:" for="mjpMinRAge">
              <b-input :value="this.$PrettyMs(guild.guildDB.data.joinageTIME, {verbose: true})" @focusout.native="undbindELTarJquery" @focus="guildmjpMinRAgeReady" id="mjpMinRAge" placeholder="Enter Min Age" maxlength="30" custom-class="input-default" style="width: 100%"></b-input>
            </b-field>
            <b-field label="Join Action" for="joinAction">
              <v-select appendToBody :selectOnTab="true" placeholder="Select a join action!" :calculatePosition="vselectfixer" @input="joinActionUpdate" :disabled="memberJoinActionDisabled" autocomplete :searchable="false" required :clearable="false" :options="['ban', 'kick', 'mute']" :getOptionLabel="option => option" :value="guild.guildDB.data.memberJoinAction">
                <template slot="option" slot-scope="option" style="width: 100%">
                  <b-tooltip type="is-warning" label="Requires mute role to be enabled & enabled else will default to kick or ban!" style="width: 100%" v-if="option.label.toLowerCase() === 'mute'">
                    <div class="" style="padding-right: 0px !important;color:white!important; padding-top: 10px; padding-bottom: 10px; width:100%">
                      <span class="Nunito">{{ option.label.charAt(0).toUpperCase() + option.label.slice(1) }}</span>
                    </div>
                  </b-tooltip>
                  <div v-else class="" style="padding-right: 0px !important;color:white!important; padding-top: 10px; padding-bottom: 10px; width:100%">
                    <span class="Nunito">{{ option.label.charAt(0).toUpperCase() + option.label.slice(1) }}</span>
                  </div>
                </template>
              </v-select>
            </b-field>
          </div>
        </div>
      </div>
      <div :class="{'is-disabled': !guild.isAdmin}" class="column is-half">
        <div class="card tile is-child has-shadow">
          <div class="card-header-title">
            <p class="title is-4 has-text-white">Mention & Emoji Protection:</p>
          </div>
          <div class="card-content">
            <b-field label="Max Mention Count:" for="maxMentionCount">
              <b-tooltip  type="is-warning" label="Enter 0 to disable!" style="width: 100%">
                <b-input :type="'number'" :value="guild.guildDB.data.mentionsPerMessage" @focusout.native="undbindELTarJquery" @focus="maxMentionCount" id="maxMentionCount" placeholder="Enter Max Amount" min="0" :required="true" custom-class="input-default" style="width: 100%"></b-input>
              </b-tooltip>
            </b-field>
            <b-field label="Max Emoji Count:" for="maxEmojiCount">
              <b-tooltip type="is-warning" label="Enter 0 to disable!" style="width: 100%">
                <b-input :type="'number'" :value="guild.guildDB.data.emoji" @focusout.native="undbindELTarJquery" @focus="maxEmojiCount" id="maxEmojiCount" placeholder="Enter Max Amount" min="0" :required="true" custom-class="input-default" style="width: 100%"></b-input>
              </b-tooltip>
            </b-field>
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div :class="{'is-disabled': !guild.isManager}" class="column is-half">
          <div class="card tile is-child has-shadow">
            <div class="card-header-title">
              <p class="title is-4 has-text-white">Data Menu</p>
            </div>
            <div class="card-content">
              <b-notification v-if="guild.isAdmin ? (!guild.guildDB.data.canDataDump) : true" type="is-warning" has-icon aria-close-label="Close notification" role="alert">
                {{ !guild.guildDB.data.canDataDump ? 'Another user has already downloaded the data dump. Please wait another week for another data dump.' : 'You do not have the permissions to download this data dump. Please get an Admin+ to download it.'}}
              </b-notification>
              <b-field class="hovermessagefeild" :message="['Only can be done once a week! (every 7 days)', 'Only Admins+ can do this!']" label="Download Data Dump" for="DDD">
                <b-button id="DDD" @click="downloadDataDump" :disabled="guild.isAdmin ? (!guild.guildDB.data.canDataDump) : true" :key="guild.guildDB.data.canDataDump" type="is-primary" :label="'Download'" expanded></b-button>
              </b-field>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
  import 'vue-select/dist/vue-select.css'
  // import JsColor from '@/components/JsColor.vue'
  import vSelect from 'vue-select'
  import { createPopper } from '@popperjs/core'

  export default {
    name: 'Mod',
    components: {
      // JsColor,
      vSelect
    },
    props: ['guildData'],
    computed: {
      guild: function () {
        return this.guildData
      }
    },
    data () {
      return {
        demoWelcomeBackgroundImage: '',
        SelectPlacement: 'bottom',
        filterBypassDisabled: false,
        muteRoleDisabled: false,
        memberJoinActionDisabled: false
      }
    },
    created () {
    },
    methods: {
      getGuild () {
        this.$parent.getGuild()
      },
      vselectfixer (dropdownList, component, { width }) {
        dropdownList.style.width = width
        const popper = createPopper(component.$refs.toggle, dropdownList, {
          placement: this.SelectPlacement,
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -1]
              }
            },
            {
              name: 'toggleClass',
              enabled: true,
              phase: 'write',
              fn ({ state }) {
                component.$el.classList.toggle('drop-up', state.SelectPlacement === 'top')
              }
            }
          ]
        })
        return () => popper.destroy()
      },
      undbindELTarJquery (el) {
        this.$(el.target).unbind()
      },
      async downloadDataDump (el) {
        el = this.$(el.target)
        el.prop('disabled', true)
        window.open(this.$store.getters._genApiUrl('guild/' + this.$route.params.id + '/datadump?token=' + this.$store.getters.authToken))
        await this.$store.dispatch('sleep', 3000)
        el.prop('disabled', false)
        this.getGuild()
      },
      muteRoleUpdate (value) {
        const beforeUpdate = this.guild.guildDB.data.muteroleID
        this.muteRoleDisabled = true
        this.guild.guildDB.data.muteroleID = value
        this.$store.dispatch('patchApi', { path: `guild/${this.$route.params.id}/muterole`, body: { id: value }, headers: {}, params: {} }).then(async (result) => {
          this.$buefy.snackbar.open({
            message: 'Mute Role was updated!',
            type: 'is-success',
            position: 'is-bottom-right'
          })
          this.muteRoleDisabled = false
          await this.$store.dispatch('sleep', 2000)
          this.getGuild()
        }).catch(async (error) => {
          console.log(error, 'er')
          this.$buefy.snackbar.open({
            message: 'Oh no! Some error happened!',
            type: 'is-danger',
            position: 'is-bottom-right'
          })
          this.guild.guildDB.data.muteroleID = beforeUpdate
          this.muteRoleDisabled = false
          await this.$store.dispatch('sleep', 2000)
          this.getGuild()
        })
      },
      filterbypassRoleUpdate (value) {
        const beforeUpdate = this.guild.guildDB.data.swearBypassRole
        this.filterBypassDisabled = true
        this.guild.guildDB.data.swearBypassRole = value
        this.$store.dispatch('patchApi', { path: `guild/${this.$route.params.id}/filterrole`, body: { ids: value }, headers: {}, params: {} }).then(async (result) => {
          this.$buefy.snackbar.open({
            message: 'Filter Bypass Role was updated!',
            type: 'is-success',
            position: 'is-bottom-right'
          })
          this.filterBypassDisabled = false
          await this.$store.dispatch('sleep', 2000)
          this.getGuild()
        }).catch(async (error) => {
          console.log(error, 'er')
          this.$buefy.snackbar.open({
            message: 'Oh no! Some error happened!',
            type: 'is-danger',
            position: 'is-bottom-right'
          })
          this.guild.guildDB.data.swearBypassRole = beforeUpdate
          this.filterBypassDisabled = false
          await this.$store.dispatch('sleep', 2000)
          this.getGuild()
        })
      },
      guildmjpMinRAgeReady (el) {
        var _this = this
        el = this.$(el.target)
        el.data('valbefore', el.val())
        el.donetyping(function (callback) {
          var value = el.val()
          if (el.data('valbefore') === value) return
          el.prop('disabled', true)
          _this.$store.dispatch('patchApi', { path: `guild/${_this.$route.params.id}/mjpMinRAge`, body: { time: value }, headers: {}, params: {} }).then(async (result) => {
            el.val(value)
            _this.$buefy.snackbar.open({
              message: 'Join Age was updated!',
              type: 'is-success',
              position: 'is-bottom-right'
            })
            el.prop('disabled', false)
            await _this.$store.dispatch('sleep', 2000)
            _this.getGuild()
          }).catch(async (error) => {
            console.log(error, 'er')
            el.val(el.data('valbefore'))
            _this.$buefy.snackbar.open({
              message: 'Oh no! Some error happened!',
              type: 'is-danger',
              position: 'is-bottom-right'
            })
            el.prop('disabled', false)
            await _this.$store.dispatch('sleep', 2000)
            _this.getGuild()
          })
        })
      },
      filterStatusUpdate (el) {
        var _this = this
        el = this.$(el.target)
        el.prop('disabled', true)
        const updateVal = this.guild.guildDB.data.swearEnable ? false : true || false
        console.log(updateVal)
        this.$store.dispatch('patchApi', { path: `guild/${this.$route.params.id}/filterstatus`, body: { status: updateVal }, headers: {}, params: {} }).then(async (result) => {
          this.$buefy.snackbar.open({
            message: 'Filter was updated!',
            type: 'is-success',
            position: 'is-bottom-right'
          })
          this.guild.guildDB.data.swearEnable = Number(updateVal)
          el.prop('disabled', false)
          await this.$store.dispatch('sleep', 2000)
          this.getGuild()
        }).catch(async (error) => {
          console.log(error, 'Error')
          this.$buefy.snackbar.open({
            message: 'Oh no! Some error happened!',
            type: 'is-danger',
            position: 'is-bottom-right'
          })
          el.prop('disabled', false)
          await this.$store.dispatch('sleep', 2000)
          this.getGuild()
        })
      },
      modStatusUpdate (el) {
        var _this = this
        el = this.$(el.target)
        el.prop('disabled', true)
        const updateVal = this.guild.guildDB.data.modEnable ? false : true || false
        console.log(updateVal)
        this.$store.dispatch('patchApi', { path: `guild/${this.$route.params.id}/modstatus`, body: { status: updateVal }, headers: {}, params: {} }).then(async (result) => {
          this.$buefy.snackbar.open({
            message: 'Moderation  was updated!',
            type: 'is-success',
            position: 'is-bottom-right'
          })
          this.guild.guildDB.data.modEnable = Number(updateVal)
          el.prop('disabled', false)
          await this.$store.dispatch('sleep', 2000)
          this.getGuild()
        }).catch(async (error) => {
          console.log(error, 'Error')
          this.$buefy.snackbar.open({
            message: 'Oh no! Some error happened!',
            type: 'is-danger',
            position: 'is-bottom-right'
          })
          el.prop('disabled', false)
          await this.$store.dispatch('sleep', 2000)
          this.getGuild()
        })
      },
      joinActionUpdate (value) {
        console.log(value, 'value')
        const beforeUpdate = this.guild.guildDB.data.memberJoinAction
        this.memberJoinActionDisabled = true
        this.guild.guildDB.data.memberJoinAction = value
        this.$store.dispatch('patchApi', { path: `guild/${this.$route.params.id}/memberJoinAction`, body: { type: value }, headers: {}, params: {} }).then(async (result) => {
          this.$buefy.snackbar.open({
            message: 'Join Action Image was updated!',
            type: 'is-success',
            position: 'is-bottom-right'
          })
          this.memberJoinActionDisabled = false
          await this.$store.dispatch('sleep', 2000)
          this.getGuild()
        }).catch(async (error) => {
          console.log(error, 'er')
          this.$buefy.snackbar.open({
            message: 'Oh no! Some error happened!',
            type: 'is-danger',
            position: 'is-bottom-right'
          })
          this.guild.guildDB.data.memberJoinAction = beforeUpdate
          this.memberJoinActionDisabled = false
          await this.$store.dispatch('sleep', 2000)
          this.getGuild()
        })
      },
      guildfilterlist (el) {
        var _this = this
        el = this.$(el.target)
        el.data('valbefore', el.val())
        el.donetyping(function (callback) {
          var value = el.val()
          if (el.data('valbefore') === value) return
          el.prop('disabled', true)
          _this.$store.dispatch('patchApi', { path: `guild/${_this.$route.params.id}/filterlist`, body: { list: value.split(/[ ,]+/) }, headers: {}, params: {} }).then(async (result) => {
            el.val(value)
            _this.$buefy.snackbar.open({
              message: 'Filter list was updated!',
              type: 'is-success',
              position: 'is-bottom-right'
            })
            el.prop('disabled', false)
            await _this.$store.dispatch('sleep', 2000)
            _this.getGuild()
          }).catch(async (error) => {
            console.log(error, 'er')
            el.val(el.data('valbefore'))
            _this.$buefy.snackbar.open({
              message: 'Oh no! Some error happened!',
              type: 'is-danger',
              position: 'is-bottom-right'
            })
            el.prop('disabled', false)
            await _this.$store.dispatch('sleep', 2000)
            _this.getGuild()
          })
        })
      },
      maxMentionCount (el) {
        var _this = this
        el = this.$(el.target)
        el.data('valbefore', Number(el.val()))
        el.donetyping(function (callback) {
          var value = Number(el.val())
          if (el.data('valbefore') === value) return
          if (Number(value) > 15) value = 15
          if (Number(value) < 0) value = 0
          el.prop('disabled', true)
          _this.$store.dispatch('patchApi', { path: `guild/${_this.$route.params.id}/mentioncount`, body: { amount: Number(value) }, headers: {}, params: {} }).then(async (result) => {
            el.val(value)
            _this.$buefy.snackbar.open({
              message: 'Mention Count was updated!',
              type: 'is-success',
              position: 'is-bottom-right'
            })
            el.prop('disabled', false)
            await _this.$store.dispatch('sleep', 2000)
            _this.getGuild()
          }).catch(async (error) => {
            console.log(error, 'er')
            el.val(el.data('valbefore'))
            _this.$buefy.snackbar.open({
              message: 'Oh no! Some error happened!',
              type: 'is-danger',
              position: 'is-bottom-right'
            })
            el.prop('disabled', false)
            await _this.$store.dispatch('sleep', 2000)
            _this.getGuild()
          })
        })
      },
      maxEmojiCount (el) {
        var _this = this
        el = this.$(el.target)
        el.data('valbefore', Number(el.val()))
        el.donetyping(function (callback) {
          var value = Number(el.val())
          if (el.data('valbefore') === value) return
          if (Number(value) > 15) value = 15
          if (Number(value) < 0) value = 0
          el.prop('disabled', true)
          _this.$store.dispatch('patchApi', { path: `guild/${_this.$route.params.id}/emojicount`, body: { amount: Number(value) }, headers: {}, params: {} }).then(async (result) => {
            el.val(value)
            _this.$buefy.snackbar.open({
              message: 'Emoji Count was updated!',
              type: 'is-success',
              position: 'is-bottom-right'
            })
            el.prop('disabled', false)
            await _this.$store.dispatch('sleep', 2000)
            _this.getGuild()
          }).catch(async (error) => {
            console.log(error, 'er')
            el.val(el.data('valbefore'))
            _this.$buefy.snackbar.open({
              message: 'Oh no! Some error happened!',
              type: 'is-danger',
              position: 'is-bottom-right'
            })
            el.prop('disabled', false)
            await _this.$store.dispatch('sleep', 2000)
            _this.getGuild()
          })
        })
      }
    }
  }
</script>

<style>
  .vs--disabled .vs__dropdown-toggle, .vs--disabled .vs__clear, .vs--disabled .vs__search, .vs--disabled .vs__selected, .vs--disabled .vs__open-indicator {
    background: var(--main-color-bg-2);
    cursor: not-allowed;
    color: #7a7a7a !important;
  }
  .vs__dropdown-toggle {
    background: var(--main-color-bg-2);
    color: white !important;
    border-color: var(--main-color-bg-3) !important;
    min-height: 2.25em !important;
    height: 100% !important;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: 0 0.1rem 1.1rem rgba(0,0,0,.3) !important;
  }
  .vs__dropdown-menu {
    background: var(--main-color-bg-1);
    color: white;
    border-color: var(--main-color-bg-3) !important;
    border-top-style: unset !important;
    border-radius: 4px 4px 4px 4px !important;
  }
  .vs__open-indicator {
    fill: #6045aa !important;
  }
  .vs__open-indicator:hover {
    fill: #7957d5 !important;
  }
  .vs__clear {
    fill: darkred !important;
  }
  .vs__clear:hover {
    fill: red !important;
  }
  .vs--single .vs__selected {
    color: white;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    overflow: hidden !important;
  }
  .vs__dropdown-option--selected {
    background-color: var(--main-color-bg-2);
  }
  .vs__dropdown-option--highlight {
    background: var(--main-color-bg-3) !important;
  }
  .vs__actions {
    cursor: pointer;
  }
  .vs__search, .vs__search:focus {
    color: white;
  }
  .vs__selected {
    background-color: var(--main-color-bg-1);
    color: white;
  }
  .vs__deselect {
    fill: darkred;
  }
  .vs__deselect:hover {
    fill: red;
  }
</style>

<style>
  .labelcolor-warning > .label {
    color: #ffdd57 !important
  }
  .help {
    color: white !important;
  }
  .hovermessagefeild:hover > .help {
    display: unset !important;
  }
  .hovermessagefeild > .help {
    display: none !important;
  }
  .is-invisible {
    display: none !important;
  }
  .has-shadow {
    box-shadow: 0 0.1rem 1.1rem rgba(0,0,0,.3)!important
  }
</style>

<style lang="scss" scoped>
.button.is-fullwidth {
  box-shadow: 0 0.1rem 1.1rem rgba(0,0,0,.3)!important;
}
.columns {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
#sectionPageMain {
  padding: 0.5rem 1.5rem !important;
  padding-bottom: 3rem !important;
}
.card {
  border-radius: 6px;
  border: 1px solid var(--main-color-bg-3);
  background: var(--main-color-bg);
  height: unset !important;
  height: 100% !important;
}
h3.subtitle.is-spaced {
  color: lightgray;
}
.p-1 {
  padding: 1em;
}
.sidebar-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    /* min-height: 100vh; */
    .sidebar-layout {
        display: flex;
        flex-direction: row;
        min-height: 100%;
        min-height: calc(100vh - ( 3.25rem - 0rem ) );
    }
}
</style>
