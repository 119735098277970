<template>
  <div>
    <div class="columns">
      <div :class="{'is-disabled': !guild.isAdmin}" class="column is-half">
        <div class="card tile is-child has-shadow">
          <div class="card-header-title" style="display: block;">
            <p class="title is-4 has-text-white">Default Log Options:</p>
            <p class="subtitle is-6 has-text-white">Grace logging systems.</p>
          </div>
          <div class="card-content">
            <b-field label="Announced Log Events Channel" for="logchannel">
              <v-select appendToBody :selectOnTab="true" placeholder="Search or Select a log channel!" :calculatePosition="vselectfixer" @input="logChannelUpdate" :disabled="logChannelDisabled" autocomplete :searchable="true" required :clearable="false" :reduce="item => item.id" :options="(guild.bot.data.channels ? guild.bot.data.channels.filter(g => Number(g.type) === 0) : [])" :value="(guild.bot.data.channels.find(g => g.id === guild.guildDB.data.logchannel) ? guild.bot.data.channels.find(g => g.id === guild.guildDB.data.logchannel) : null)" :getOptionLabel="option => `#${option.name}`" label="Log Channel">
                <template slot="option" slot-scope="option" style="width: 100%">
                  <b-tooltip :label="`ID: ${option.id}`" style="width: 100%" position="is-bottom" multilined>
                    <div class="" style="padding-right: 0px !important;color:white!important; padding-top: 10px; padding-bottom: 10px; width:100%">
                      <span class="Nunito" :style="`color: white;`">#{{ option.name }}</span> <b-tag class="Nunito" v-if="option.nsfw" rounded type="is-danger">NSFW</b-tag>
                    </div>
                  </b-tooltip>
                </template>
              </v-select>
            </b-field>
            <b-field class="hovermessagefeild" :message="'Current Status: ' + (guild.guildDB.data.logEnable ? 'Enabled' : 'Disabled') + '. System events like prefix updates etc will still be logged but not announced on any setting.'" label="Log Status" for="logstatus">
                <b-button id="logstatus" @click="logStatusUpdate" :key="guild.guildDB.data.logEnable" v-bind:type="{'is-danger': (guild.guildDB.data.logEnable ? true : false), 'is-success': (!guild.guildDB.data.logEnable ? true : false)}" v-bind:label="(guild.guildDB.data.logEnable ? 'Disable Logging': 'Enable Logging')" expanded></b-button>
            </b-field>
          </div>
        </div>
      </div>
      <div :class="{'is-disabled': !guild.isManager}" class="column is-half">
        <div class="card tile is-child has-shadow">
          <div class="card-header-title" style="display: block;">
            <p class="title is-4 has-text-white">Suggest Command Options:</p>
            <p class="subtitle is-6 has-text-white"><code style="background-color: var(--main-color-bg-2);">{{guild.guildDB.data.prefix}}suggest MSG</code>, to create a suggestion.</p>
          </div>
          <div class="card-content">
            <b-field label="Suggest Channel" for="suggestChannel">
              <v-select appendToBody :selectOnTab="true" placeholder="Search or Select a Suggest channel!" :calculatePosition="vselectfixer" @input="suggestChannelUpdate" :disabled="suggestChannelDisabled" autocomplete :searchable="true" required :clearable="false" :reduce="item => item.id" :options="(guild.bot.data.channels ? guild.bot.data.channels.filter(g => Number(g.type) === 0) : [])" :value="(guild.bot.data.channels.find(g => g.id === guild.guildDB.data.suggestChannelID) ? guild.bot.data.channels.find(g => g.id === guild.guildDB.data.suggestChannelID) : null)" :getOptionLabel="option => `#${option.name}`" label="Suggest Channel">
                <template slot="option" slot-scope="option" style="width: 100%">
                  <b-tooltip :label="`ID: ${option.id}`" style="width: 100%" position="is-bottom" multilined>
                    <div class="" style="padding-right: 0px !important;color:white!important; padding-top: 10px; padding-bottom: 10px; width:100%">
                      <span class="Nunito" :style="`color: white;`">#{{ option.name }}</span> <b-tag class="Nunito" v-if="option.nsfw" rounded type="is-danger">NSFW</b-tag>
                    </div>
                  </b-tooltip>
                </template>
              </v-select>
            </b-field>
            <b-field class="hovermessagefeild" :message="'Current Status: ' + (guild.guildDB.data.suggestEnable ? 'Enabled' : 'Disabled') " label="Suggest Status" for="suggeststatus">
              <b-button id="suggeststatus" @click="suggestStatusUpdate" :key="guild.guildDB.data.suggestEnable" v-bind:type="{'is-danger': (guild.guildDB.data.suggestEnable ? true : false), 'is-success': (!guild.guildDB.data.suggestEnable ? true : false)}" v-bind:label="(guild.guildDB.data.suggestEnable ? 'Disable Suggest Command': 'Enable Suggest Command')" expanded></b-button>
            </b-field>
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div :class="{'is-disabled': !guild.isManager || !guild.guildDB.data.logEnable}" class="column is-half">
        <div class="card tile is-child has-shadow">
          <div class="card-header-title" style="display: block;">
            <p class="title is-4 has-text-white">Audit Log Events:</p>
            <p class="subtitle is-6 has-text-white">All the events we will log and show in Audit Logs.{{ guild.guildDB.data.logEnable ? '' : ' Enable Logging to allow Events to be logged!'}}</p>
          </div>
          <div class="card-content">
            <b-field label="Message Events">
              <b-switch @input="updateEventStatus($event, 'MessageDelete')" :value="guild.guildDB.data.logMessageDelete ? true : false" id="logMessageDelete" type="is-success">Message Deleted</b-switch>
              <b-switch @input="updateEventStatus($event, 'MessageUpdate')" :value="guild.guildDB.data.logMessageUpdate ? true : false" id="logMessageUpdate" type="is-success">Message Updated</b-switch>
            </b-field>
            <b-field label="Role Events" v-if="guild.guildDB.data.beta">
              <b-switch :value="guild.guildDB.data.logRoleCreateDelete ? true : false" type="is-success">Role Created/Deleted</b-switch>
              <b-switch :value="guild.guildDB.data.logRoleUpdate ? true : false" type="is-success">Role Updated</b-switch>
            </b-field>
            <b-field label="Guild/Server Events" v-if="guild.guildDB.data.beta">
              <b-switch :value="guild.guildDB.data.logGuildUpdate ? true : false" type="is-success">Server Updated</b-switch>
            </b-field>
            <b-field label="Channel Events" v-if="guild.guildDB.data.beta">
              <b-switch :value="guild.guildDB.data.logChannelCreateDelete ? true : false" type="is-success">Channel Created/Deleted</b-switch>
              <b-switch :value="guild.guildDB.data.logChannelUpdate ? true : false" type="is-success">Channel Updated</b-switch>
            </b-field>
            <b-field label="Emoji Events" v-if="guild.guildDB.data.beta">
              <b-switch :value="guild.guildDB.data.logEmojiCreateDelete ? true : false" type="is-success">Emoji Created/Deleted</b-switch>
              <b-switch :value="guild.guildDB.data.logEmojiUpdate ? true : false" type="is-success">Emoji Updated</b-switch>
            </b-field>
            <b-field label="Member Events" v-if="guild.guildDB.data.beta" message="Join/Leave Event will not control Welcome Settings">
              <b-switch @input="updateEventStatus($event, 'MemberJoinLeave')" id="logMemberJoinLeave" :value="guild.guildDB.data.logMemberJoinLeave" type="is-success">Member Join/Leave</b-switch>
              <b-switch @input="updateEventStatus($event, 'MemberUpdate')" id="logMemberUpdate" :disabled="!guild.guildDB.data.premium" :value="!guild.guildDB.data.premium ? false : (guild.guildDB.data.logMemberUpdate ? true : false)" type="is-success">Member Updated <b-tag v-if="!guild.guildDB.data.premium" type="is-warning">PREMIUM REQUIRED</b-tag></b-switch>
              <b-switch @input="updateEventStatus($event, 'MemberVoiceStatus')" id="logMemberVoiceStatus" :disabled="!guild.guildDB.data.premium" :value="!guild.guildDB.data.premium ? false : (guild.guildDB.data.logMemberVoiceStatus ? true : false)" type="is-success">Member Voice Status <b-tag v-if="!guild.guildDB.data.premium" type="is-warning">PREMIUM REQUIRED</b-tag></b-switch>
            </b-field>
          </div>
        </div>
      </div>
      <div :class="{'is-disabled': !guild.isManager}" class="column is-half">
        <div class="card tile is-child has-shadow">
          <div class="card-header-title" style="display: block;">
            <p class="title is-4 has-text-white">Welcome Options:</p>
            <p class="subtitle is-6 has-text-white">Grace welcome systems.</p>
          </div>
          <div class="card-content">
            <b-field label="Welcome Channel" for="welcomechannel">
              <v-select appendToBody :selectOnTab="true" placeholder="Search or Select a log channel!" :calculatePosition="vselectfixer" @input="welcomeChannelUpdate" :disabled="welcomeChannelDisabled" autocomplete :searchable="true" required :clearable="false" :reduce="item => item.id" :options="(guild.bot.data.channels ? guild.bot.data.channels.filter(g => Number(g.type) === 0) : [])" :value="(guild.bot.data.channels.find(g => g.id === guild.guildDB.data.welcomeChannel) ? guild.bot.data.channels.find(g => g.id === guild.guildDB.data.welcomeChannel) : null)" :getOptionLabel="option => `#${option.name}`" label="Welcome Channel">
                <template slot="option" slot-scope="option" style="width: 100%">
                  <b-tooltip :label="`ID: ${option.id}`" style="width: 100%" position="is-bottom" multilined>
                    <div class="" style="padding-right: 0px !important;color:white!important; padding-top: 10px; padding-bottom: 10px; width:100%">
                      <span class="Nunito" :style="`color: white;`">#{{ option.name }}</span> <b-tag class="Nunito" v-if="option.nsfw" rounded type="is-danger">NSFW</b-tag>
                    </div>
                  </b-tooltip>
                </template>
              </v-select>
            </b-field>
            <b-field label="Welcome Auto Role" for="welcomeAutoRole">
              <b-tooltip type="is-warning" label="Deleted roles will be filtered out!" style="width: 100%">
                <v-select id="welcomeAutoRole" style="width: 100%;" appendToBody :selectOnTab="true" placeholder="Search or Select a bypass role!" :calculatePosition="vselectfixer" @input="welcomeAutoRoleUpdate" :disabled="welcomeAutoRoleDisabled" autocomplete :searchable="true" :multiple="true" required :clearable="true" :reduce="item => item.id" :options="(guild.bot.data.roles ? guild.bot.data.roles.filter(g => g.managed === false && g.name !== '@everyone') : [])" :value="guild.guildDB.data.welcomeAutoRole" :getOptionLabel="option => `@${(option ? option.name : 'MISSING')}`" label="Welcome Auto Role">
                  <template slot="option" slot-scope="option" style="width: 100%">
                    <b-tooltip :label="`ID: ${option.id}`" style="width: 100%" position="is-bottom" multilined>
                      <div class="" style="padding-right: 0px !important;color:white!important; padding-top: 10px; padding-bottom: 10px; width:100%">
                        <span class="Nunito" :style="`color: #${option.color.toString(16).padStart(6, '0')}`">@{{ option.name }}</span> <b-tag class="Nunito" v-if="option.isAdmin" rounded type="is-danger">Admin</b-tag> <b-tag class="Nunito" v-if="option.isManager" rounded type="is-warning">Manage</b-tag>
                      </div>
                    </b-tooltip>
                  </template>
                </v-select>
              </b-tooltip>
            </b-field>
            <b-field class="hovermessagefeild" :message="'Current Status: ' + (guild.guildDB.data.welcomeEnable ? 'Enabled' : 'Disabled') " label="Welcome Status" for="welcomestatus">
              <b-button id="welcomestatus" @click="welcomeStatusUpdate" :key="guild.guildDB.data.welcomeEnable" v-bind:type="{'is-danger': (guild.guildDB.data.welcomeEnable ? true : false), 'is-success': (!guild.guildDB.data.welcomeEnable ? true : false)}" v-bind:label="(guild.guildDB.data.welcomeEnable ? 'Disable Welcome': 'Enable Welcome')" expanded></b-button>
            </b-field>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import 'vue-select/dist/vue-select.css'
  // import JsColor from '@/components/JsColor.vue'
  import vSelect from 'vue-select'
  import { createPopper } from '@popperjs/core'

  export default {
    name: 'Log',
    components: {
      // JsColor,
      vSelect
    },
    props: ['guildData'],
    computed: {
      guild: function () {
        return this.guildData
      }
    },
    data () {
      return {
        SelectPlacement: 'bottom',
        logChannelDisabled: false,
        welcomeChannelDisabled: false,
        welcomeAutoRoleDisabled: false,
        suggestChannelDisabled: false
      }
    },
    created () {
    },
    methods: {
      getGuild () {
        this.$parent.getGuild()
      },
      vselectfixer (dropdownList, component, { width }) {
        dropdownList.style.width = width
        const popper = createPopper(component.$refs.toggle, dropdownList, {
          placement: this.SelectPlacement,
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -1]
              }
            },
            {
              name: 'toggleClass',
              enabled: true,
              phase: 'write',
              fn ({ state }) {
                component.$el.classList.toggle('drop-up', state.SelectPlacement === 'top')
              }
            }
          ]
        })
        return () => popper.destroy()
      },
      undbindELTarJquery (el) {
        this.$(el.target).unbind()
      },
      updateEventStatus (value, event) {
        console.log(value, event)
        var _this = this
        var el = this.$(`#log${event}`)
        el.addClass('is-disabled-noCheck')
        this.$store.dispatch('patchApi', { path: `guild/${this.$route.params.id}/eventstatus`, body: { event: event, status: value }, headers: {}, params: {} }).then(async (result) => {
          this.$buefy.snackbar.open({
            message: event + ' was updated!',
            type: 'is-success',
            position: 'is-bottom-right'
          })
          this.guild.guildDB.data[`log${event}`] = value
          el.removeClass('is-disabled-noCheck')
          await this.$store.dispatch('sleep', 2000)
          this.getGuild()
        }).catch(async (error) => {
          console.log(error, 'Error')
          this.$buefy.snackbar.open({
            message: 'Oh no! Some error happened!',
            type: 'is-danger',
            position: 'is-bottom-right'
          })
          this.guild.guildDB.data[`log${event}`] = !value
          await this.$store.dispatch('sleep', 2000)
          this.getGuild()
          el.removeClass('is-disabled-noCheck')
        })
        // removeClass
      },
      suggestStatusUpdate (el) {
        var _this = this
        el = this.$(el.target)
        el.prop('disabled', true)
        const updateVal = this.guild.guildDB.data.suggestEnable ? false : true || false
        console.log(updateVal, el)
        this.$store.dispatch('patchApi', { path: `guild/${this.$route.params.id}/suggeststatus`, body: { status: updateVal }, headers: {}, params: {} }).then(async (result) => {
          this.$buefy.snackbar.open({
            message: 'Log Status was updated!',
            type: 'is-success',
            position: 'is-bottom-right'
          })
          this.guild.guildDB.data.suggestEnable = Number(updateVal)
          el.prop('disabled', false)
          await this.$store.dispatch('sleep', 2000)
          this.getGuild()
        }).catch(async (error) => {
          console.log(error, 'Error')
          this.$buefy.snackbar.open({
            message: 'Oh no! Some error happened!',
            type: 'is-danger',
            position: 'is-bottom-right'
          })
          el.prop('disabled', false)
          await this.$store.dispatch('sleep', 2000)
          this.getGuild()
        })
      },
      suggestChannelUpdate (value) {
        const beforeUpdate = this.guild.guildDB.data.suggestChannelID
        this.suggestChannelDisabled = true
        this.guild.guildDB.data.suggestChannelID = value
        this.$store.dispatch('patchApi', { path: `guild/${this.$route.params.id}/suggestchannel`, body: { id: value }, headers: {}, params: {} }).then(async (result) => {
          this.$buefy.snackbar.open({
            message: 'Log Channel was updated!',
            type: 'is-success',
            position: 'is-bottom-right'
          })
          this.suggestChannelDisabled = false
          await this.$store.dispatch('sleep', 2000)
          this.getGuild()
        }).catch(async (error) => {
          console.log(error, 'er')
          this.$buefy.snackbar.open({
            message: 'Oh no! Some error happened!',
            type: 'is-danger',
            position: 'is-bottom-right'
          })
          this.guild.guildDB.data.suggestChannelID = beforeUpdate
          this.suggestChannelDisabled = false
          await this.$store.dispatch('sleep', 2000)
          this.getGuild()
        })
      },
      logChannelUpdate (value) {
        const beforeUpdate = this.guild.guildDB.data.logchannel
        this.logChannelDisabled = true
        this.guild.guildDB.data.logchannel = value
        this.$store.dispatch('patchApi', { path: `guild/${this.$route.params.id}/logchannel`, body: { id: value }, headers: {}, params: {} }).then(async (result) => {
          this.$buefy.snackbar.open({
            message: 'Log Channel was updated!',
            type: 'is-success',
            position: 'is-bottom-right'
          })
          this.logChannelDisabled = false
          await this.$store.dispatch('sleep', 2000)
          this.getGuild()
        }).catch(async (error) => {
          console.log(error, 'er')
          this.$buefy.snackbar.open({
            message: 'Oh no! Some error happened!',
            type: 'is-danger',
            position: 'is-bottom-right'
          })
          this.guild.guildDB.data.logchannel = beforeUpdate
          this.logChannelDisabled = false
          await this.$store.dispatch('sleep', 2000)
          this.getGuild()
        })
      },
      welcomeAutoRoleUpdate (value) {
        const beforeUpdate = this.guild.guildDB.data.welcomeAutoRole
        this.welcomeAutoRoleDisabled = true
        this.guild.guildDB.data.welcomeAutoRole = value
        this.$store.dispatch('patchApi', { path: `guild/${this.$route.params.id}/welcomeroles`, body: { ids: value }, headers: {}, params: {} }).then(async (result) => {
          this.$buefy.snackbar.open({
            message: 'Welcome Auto Roles was updated!',
            type: 'is-success',
            position: 'is-bottom-right'
          })
          this.welcomeAutoRoleDisabled = false
          await this.$store.dispatch('sleep', 2000)
          this.getGuild()
        }).catch(async (error) => {
          console.log(error, 'er')
          this.$buefy.snackbar.open({
            message: 'Oh no! Some error happened!',
            type: 'is-danger',
            position: 'is-bottom-right'
          })
          this.guild.guildDB.data.welcomeAutoRole = beforeUpdate
          this.welcomeAutoRoleDisabled = false
          await this.$store.dispatch('sleep', 2000)
          this.getGuild()
        })
      },
      logStatusUpdate (el) {
        var _this = this
        el = this.$(el.target)
        el.prop('disabled', true)
        const updateVal = this.guild.guildDB.data.logEnable ? false : true || false
        console.log(updateVal)
        this.$store.dispatch('patchApi', { path: `guild/${this.$route.params.id}/logstatus`, body: { status: updateVal }, headers: {}, params: {} }).then(async (result) => {
          this.$buefy.snackbar.open({
            message: 'Log Status was updated!',
            type: 'is-success',
            position: 'is-bottom-right'
          })
          this.guild.guildDB.data.logEnable = Number(updateVal)
          el.prop('disabled', false)
          await this.$store.dispatch('sleep', 2000)
          this.getGuild()
        }).catch(async (error) => {
          console.log(error, 'Error')
          this.$buefy.snackbar.open({
            message: 'Oh no! Some error happened!',
            type: 'is-danger',
            position: 'is-bottom-right'
          })
          el.prop('disabled', false)
          await this.$store.dispatch('sleep', 2000)
          this.getGuild()
        })
      },
      welcomeStatusUpdate (el) {
        var _this = this
        el = this.$(el.target)
        el.prop('disabled', true)
        const updateVal = this.guild.guildDB.data.welcomeEnable ? false : true || false
        console.log(updateVal)
        this.$store.dispatch('patchApi', { path: `guild/${this.$route.params.id}/welcomestatus`, body: { status: updateVal }, headers: {}, params: {} }).then(async (result) => {
          this.$buefy.snackbar.open({
            message: 'Log Status was updated!',
            type: 'is-success',
            position: 'is-bottom-right'
          })
          this.guild.guildDB.data.welcomeEnable = Number(updateVal)
          el.prop('disabled', false)
          await this.$store.dispatch('sleep', 2000)
          this.getGuild()
        }).catch(async (error) => {
          console.log(error, 'Error')
          this.$buefy.snackbar.open({
            message: 'Oh no! Some error happened!',
            type: 'is-danger',
            position: 'is-bottom-right'
          })
          el.prop('disabled', false)
          await this.$store.dispatch('sleep', 2000)
          this.getGuild()
        })
      },
      welcomeChannelUpdate (value) {
        const beforeUpdate = this.guild.guildDB.data.welcomeChannel
        this.welcomeChannelDisabled = true
        this.guild.guildDB.data.welcomeChannel = value
        this.$store.dispatch('patchApi', { path: `guild/${this.$route.params.id}/welcomechannel`, body: { id: value }, headers: {}, params: {} }).then(async (result) => {
          this.$buefy.snackbar.open({
            message: 'Welcome Channel was updated!',
            type: 'is-success',
            position: 'is-bottom-right'
          })
          this.welcomeChannelDisabled = false
          await this.$store.dispatch('sleep', 2000)
          this.getGuild()
        }).catch(async (error) => {
          console.log(error, 'er')
          this.$buefy.snackbar.open({
            message: 'Oh no! Some error happened!',
            type: 'is-danger',
            position: 'is-bottom-right'
          })
          this.guild.guildDB.data.welcomeChannel = beforeUpdate
          this.welcomeChannelDisabled = false
          await this.$store.dispatch('sleep', 2000)
          this.getGuild()
        })
      }
    }
  }
</script>

<style>
  .vs--disabled .vs__dropdown-toggle, .vs--disabled .vs__clear, .vs--disabled .vs__search, .vs--disabled .vs__selected, .vs--disabled .vs__open-indicator {
    background: var(--main-color-bg-2);
    cursor: not-allowed;
    color: #7a7a7a !important;
  }
  .vs__dropdown-toggle {
    background: var(--main-color-bg-2);
    color: white !important;
    border-color: var(--main-color-bg-3) !important;
    min-height: 2.25em !important;
    height: 100% !important;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: 0 0.1rem 1.1rem rgba(0,0,0,.3) !important;
  }
  .vs__dropdown-menu {
    background: var(--main-color-bg-1);
    color: white;
    border-color: var(--main-color-bg-3) !important;
    border-top-style: unset !important;
    border-radius: 4px 4px 4px 4px !important;
  }
  .vs__open-indicator {
    fill: #6045aa !important;
  }
  .vs__open-indicator:hover {
    fill: #7957d5 !important;
  }
  .vs__clear {
    fill: darkred !important;
  }
  .vs__clear:hover {
    fill: red !important;
  }
  .vs--single .vs__selected {
    color: white;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    overflow: hidden !important;
  }
  .vs__dropdown-option--selected {
    background-color: var(--main-color-bg-2);
  }
  .vs__dropdown-option--highlight {
    background: var(--main-color-bg-3) !important;
  }
  .vs_actions {
    cursor: pointer;
  }
  .vs__selected {
    background-color: var(--main-color-bg-1);
    color: white;
  }
  .vs__deselect {
    fill: darkred;
  }
  .vs__deselect:hover {
    fill: red;
  }
</style>

<style>
  .labelcolor-warning > .label {
    color: #ffdd57 !important
  }
  .help {
    color: white !important;
  }
  .hovermessagefeild:hover > .help {
    display: unset !important;
  }
  .hovermessagefeild > .help {
    display: none !important;
  }
  .is-invisible {
    display: none !important;
  }
  .has-shadow {
    box-shadow: 0 0.1rem 1.1rem rgba(0,0,0,.3)!important
  }
</style>

<style lang="scss" scoped>
.button.is-fullwidth {
  box-shadow: 0 0.1rem 1.1rem rgba(0,0,0,.3)!important;
}
.columns {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
#sectionPageMain {
  padding: 0.5rem 1.5rem !important;
  padding-bottom: 3rem !important;
}
.card {
  border-radius: 6px;
  border: 1px solid var(--main-color-bg-3);
  background: var(--main-color-bg);
  height: unset !important;
}
h3.subtitle.is-spaced {
  color: lightgray;
}
.p-1 {
  padding: 1em;
}
.sidebar-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    /* min-height: 100vh; */
    .sidebar-layout {
        display: flex;
        flex-direction: row;
        min-height: 100%;
        min-height: calc(100vh - ( 3.25rem - 0rem ) );
    }
}
</style>
