<template>
  <div>
    <div class="columns">
        <div :class="{'is-disabled': !guild.isManager}" class="column is-half">
          <div class="card tile is-child has-shadow">
            <div class="card-header-title" style="display: block;">
              <p class="title is-4 has-text-white">Discord Settings</p>
              <p class="subtitle is-6 has-text-white">Change settings on how you interact with Grace!</p>
            </div>
            <div class="card-content">
              <b-field label="Prefix" for="prefix">
                <b-input :value="guild.guildDB.data.prefix" @focusout.native="undbindELTarJquery" @focus="guildPrefixReady" id="prefix" placeholder="Enter Prefix" maxlength="10" custom-class="input-default"></b-input>
              </b-field>
              <b-field label="Bot Nickname" for="nick">
                <b-input :value="guild.bot.data.self.nick" @focusout.native="undbindELTarJquery" @focus="guildNickReady" id="nick" placeholder="Enter Bot Nickname" maxlength="32" custom-class="input-default"></b-input>
              </b-field>
            </div>
          </div>
        </div>
        <div :class="{'is-disabled': !guild.isManager}" class="column is-half">
            <div class="card tile is-child has-shadow">
                <div class="card-header-title" style="display: block;">
                  <p class="title is-4 has-text-white">Other Bot Configuration:</p>
                  <p class="subtitle is-6 has-text-white">Update extra Bot settings that dont exactly fit into a category.</p>
                </div>
                <div class="card-content">
                <b-field class="hovermessagefeild" :message="'Current Status: ' + (guild.guildDB.data.nsfw ? 'Enabled' : 'Disabled') " label="NSFW Status" for="nsfw">
                    <b-button id="nsfw" @click="nsfwStatusUpdate" :key="guild.guildDB.data.nsfw" v-bind:type="{'is-danger': (guild.guildDB.data.nsfw ? true : false), 'is-success': (!guild.guildDB.data.nsfw ? true : false)}" v-bind:label="(guild.guildDB.data.nsfw ? 'Disable NSFW': 'Enable NSFW')" expanded></b-button>
                </b-field>
                <b-field label="Guild Color" for="color">
                    <JsColor @finish="guildColorUpdate" id="color" v-model="guild.guildDB.data.color"></JsColor>
                </b-field>
                </div>
            </div>
        </div>
    </div>
    <div class="columns">
      <div v-if="this.defaultwelcomeImages.length > 0" :class="{'is-disabled': !guild.isManager}" class="column is-half">
          <!-- add is-half above to make it hafl -->
          <div class="card tile is-child has-shadow">
          <div class="card-header-title" style="display: block;">
            <p class="title is-4 has-text-white">Welcome Image Settings</p>
            <p class="subtitle is-6 has-text-white">Change the welcome image that new users see!</p>
          </div>
          <div class="card-content">
              <b-field label="Change Background" for="welcomebackground">
                <v-select appendToBody :calculatePosition="vselectfixer" @input="welcomeBackgroundChanged" :disabled="welcomeBackgroundImageDisabled" :searchable="false" required :clearable="false" :reduce="item => item" :options="(guild.guildDB.data.premium ? this.defaultwelcomeImages.filter(g => g.show === true ) : this.defaultwelcomeImages.filter(g => g.show === true).filter(g => g.premiumOnly === false))" :value="guild.guildDB.data.welcomeBackground" label="title">
                    <template slot="option" slot-scope="option">
                        <div class="columns level" style="padding-right: 0px !important;">
                          <div class="column is-half" style="text-overflow: ellipsis;white-space: pre-line;overflow: hidden;color: white;">
                              {{ option.title }}
                          </div>
                          <div class="column is-half">
                              <img :src="option.cardImage" width="200" />
                          </div>
                        </div>
                    </template>
                </v-select>
              </b-field>
              <b-field label="Demo Image" for="demowelcomeimage">
                <div>
                  <img :src="demoWelcomeBackgroundImage" />
                </div>
              </b-field>
          </div>
        </div>
      </div>
      <div :class="{'is-disabled': !guild.isManager}" class="column is-half">
        <div class="card tile is-child has-shadow">
          <div class="card-header-title" style="display: block;">
            <b-tag class="is-pulled-right" type="is-danger">NEW</b-tag>
            <p class="title is-4 has-text-white">Slash Commands:</p>
            <p class="subtitle is-6 has-text-white">Get the current info on slash commands.</p>
          </div>
          <div class="card-content">
            <b-field label="Current Sync Status" for="LastUpdated">
              <p v-if="(Number(guild.guildDB.data.slashCommandSyncLastUpdated) || 1) > 1" class="subtitle is-6 has-text-white">Slash commands were last synced <b>{{$MomentJS(Number(guild.guildDB.data.slashCommandSyncLastUpdated) * 1000).fromNow()}}</b>, and will be resynced every few hours if any settings change that disable/enable commands were updated.</p>
              <p v-else class="subtitle is-6 has-text-white">Slash commands were never synced, we will try to synced very soon. If you need help please join our support discord</p>
            </b-field>
            <b-field label="Slash Commands Not Working?">
              <p class="subtitle is-6 has-text-white" style="margin-bottom: 10px !important;">You may need to reinvite the bot for slash commands to work. If you have admin perms or if the bot was invited with admin perms we recommend inviting <b>with admin perms</b>.<br>Users also need the perm <code style="background-color: var(--main-color-bg-2);">USE_APPLICATION_COMMANDS</code> to use slash commands. Its recommend to assign it to <code style="background-color: var(--main-color-bg-2);">@everyone</code> and all other roles.</p>
              <div class="buttons is-centered">
                <b-button type="is-info" tag="router-link" :to="{ path: '/invite', query: {guild_id: guild.id}}">Reinvite Bot</b-button>
                <b-button :disabled="!guild.isAdmin" type="is-info" tag="router-link" :to="{ path: '/invite', query: {isAdmin: true, guild_id: guild.id} }">Reinvite Bot w/ Admin Perms</b-button>
              </div>
            </b-field>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import 'vue-select/dist/vue-select.css'
  import JsColor from '@/components/JsColor.vue'
  import vSelect from 'vue-select'
  import { createPopper } from '@popperjs/core'

  export default {
    name: 'Main',
    components: {
      JsColor,
      vSelect
    },
    props: ['guildData'],
    computed: {
      guild: function () {
        return this.guildData
      }
    },
    data () {
      return {
        SelectPlacement: 'bottom',
        welcomeBackgroundImageDisabled: false,
        defaultwelcomeImages: []
      }
    },
    created () {
      this.genDemoBackgroundImage()
    },
    methods: {
      getGuild () {
        this.$parent.getGuild()
        this.genDemoBackgroundImage()
      },
      vselectfixer (dropdownList, component, { width }) {
        dropdownList.style.width = width
        const popper = createPopper(component.$refs.toggle, dropdownList, {
          placement: this.SelectPlacement,
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -1]
              }
            },
            {
              name: 'toggleClass',
              enabled: true,
              phase: 'write',
              fn ({ state }) {
                component.$el.classList.toggle('drop-up', 'top')
              }
            }
          ]
        })
        return () => popper.destroy()
      },
      nsfwStatusUpdate (el) {
        var _this = this
        el = this.$(el.target)
        el.prop('disabled', true)
        const updateVal = this.guild.guildDB.data.nsfw ? false : true || false
        console.log(updateVal)
        this.$store.dispatch('patchApi', { path: `guild/${this.$route.params.id}/nsfw`, body: { status: updateVal }, headers: {}, params: {} }).then(async (result) => {
          this.$buefy.snackbar.open({
            message: 'NSFW was updated!',
            type: 'is-success',
            position: 'is-bottom-right'
          })
          this.guild.guildDB.data.nsfw = Number(updateVal)
          el.prop('disabled', false)
          await this.$store.dispatch('sleep', 2000)
          this.getGuild()
        }).catch(async (error) => {
          console.log(error, 'Error')
          this.$buefy.snackbar.open({
            message: 'Oh no! Some error happened!',
            type: 'is-danger',
            position: 'is-bottom-right'
          })
          el.prop('disabled', false)
          await this.$store.dispatch('sleep', 2000)
          this.getGuild()
        })
      },
      guildColorUpdate (value) {
        console.log(value)
        this.$store.dispatch('patchApi', { path: `guild/${this.$route.params.id}/color`, body: { color: value }, headers: {}, params: {} }).then(async (result) => {
          this.$buefy.snackbar.open({
            message: 'Color was updated!',
            type: 'is-success',
            position: 'is-bottom-right'
          })
          await this.$store.dispatch('sleep', 2000)
          this.getGuild()
        }).catch(async (error) => {
          console.log(error, 'Error')
          this.$buefy.snackbar.open({
            message: 'Oh no! Some error happened!',
            type: 'is-danger',
            position: 'is-bottom-right'
          })
          await this.$store.dispatch('sleep', 2000)
          this.getGuild()
        })
      },
      guildPrefixReady (el) {
        var _this = this
        el = this.$(el.target)
        el.data('valbefore', el.val())
        el.donetyping(function (callback) {
          var value = el.val()
          if (el.data('valbefore') === value) return
          el.prop('disabled', true)
          _this.$store.dispatch('patchApi', { path: `guild/${_this.$route.params.id}/prefix`, body: { prefix: value }, headers: {}, params: {} }).then(async (result) => {
            el.val(value)
            _this.$buefy.snackbar.open({
              message: 'Prefix was updated!',
              type: 'is-success',
              position: 'is-bottom-right'
            })
            el.prop('disabled', false)
            await _this.$store.dispatch('sleep', 2000)
            _this.getGuild()
          }).catch(async (error) => {
            console.log(error, 'er')
            el.val(el.data('valbefore'))
            _this.$buefy.snackbar.open({
              message: 'Oh no! Some error happened!',
              type: 'is-danger',
              position: 'is-bottom-right'
            })
            el.prop('disabled', false)
            await _this.$store.dispatch('sleep', 2000)
            _this.getGuild()
          })
        })
      },
      guildNickReady (el) {
        var _this = this
        el = this.$(el.target)
        el.data('valbefore', el.val())
        el.donetyping(function (callback) {
          var value = el.val()
          if (el.data('valbefore') === value) return
          el.prop('disabled', true)
          _this.$store.dispatch('patchApi', { path: `guild/${_this.$route.params.id}/nick`, body: { nick: value }, headers: {}, params: {} }).then(async (result) => {
            el.val(value)
            _this.$buefy.snackbar.open({
              message: 'Nickname was updated!',
              type: 'is-success',
              position: 'is-bottom-right'
            })
            el.prop('disabled', false)
            await _this.$store.dispatch('sleep', 2000)
            _this.getGuild()
          }).catch(async (error) => {
            console.log(error, 'er')
            el.val(el.data('valbefore'))
            _this.$buefy.snackbar.open({
              message: 'Oh no! Some error happened!',
              type: 'is-danger',
              position: 'is-bottom-right'
            })
            el.prop('disabled', false)
            await _this.$store.dispatch('sleep', 2000)
            _this.getGuild()
          })
        })
      },
      welcomeBackgroundChanged (value) {
        const beforeUpdate = this.guild.guildDB.data.welcomeBackground
        this.welcomeBackgroundImageDisabled = true
        this.guild.guildDB.data.welcomeBackground = value
        this.$store.dispatch('patchApi', { path: `guild/${this.$route.params.id}/welcomebackgroundimage`, body: { imageName: value.fileName }, headers: {}, params: {} }).then(async (result) => {
          this.$buefy.snackbar.open({
            message: 'Welcome Background Image was updated!',
            type: 'is-success',
            position: 'is-bottom-right'
          })
          this.welcomeBackgroundImageDisabled = false
          await this.$store.dispatch('sleep', 2000)
          this.getGuild()
        }).catch(async (error) => {
          console.log(error, 'er')
          this.$buefy.snackbar.open({
            message: 'Oh no! Some error happened!',
            type: 'is-danger',
            position: 'is-bottom-right'
          })
          this.guild.guildDB.data.welcomeBackground = beforeUpdate
          this.welcomeBackgroundImageDisabled = false
          await this.$store.dispatch('sleep', 2000)
          this.getGuild()
        })
      },
      undbindELTarJquery (el) {
        this.$(el.target).unbind()
      },
      genDemoBackgroundImage () {
        if (this.defaultwelcomeImages.length <= 0) {
          this.$store.dispatch('getApi', { path: 'system/welcomeImages', params: (this.$store.getters.build === 'development' ? { url: `${window.location.protocol}//${window.location.host}` } : {}) }).then(async (result) => {
            this.defaultwelcomeImages = result.data
            this.demoWelcomeBackgroundImage = this.$store.getters._genApiUrl(`plugin/welcome/image?type=default&username=USERNAME&guildName=SERVERNAME&guildCount=999&background=${this.defaultwelcomeImages.find(g => g.fileName === this.guild.guildDB.data.welcomeBackground)?.fileName}`)
          }).catch(async (error) => {
            console.log(error, error.response, 'Error')
            this.defaultwelcomeImages = []
          })
        }
      }
    }
  }
</script>

<style>
  .vs--disabled .vs__dropdown-toggle, .vs--disabled .vs__clear, .vs--disabled .vs__search, .vs--disabled .vs__selected, .vs--disabled .vs__open-indicator {
    background: var(--main-color-bg-2);
    cursor: not-allowed;
    color: #7a7a7a !important;
  }
  .vs__dropdown-toggle {
    background: var(--main-color-bg-2);
    color: white !important;
    border-color: var(--main-color-bg-3) !important;
    min-height: 2.25em !important;
    height: 100% !important;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: 0 0.1rem 1.1rem rgba(0,0,0,.3) !important;
  }
  .vs__dropdown-menu {
    background: var(--main-color-bg-1);
    color: white;
    border-color: var(--main-color-bg-3) !important;
    border-top-style: unset !important;
    border-radius: 4px 4px 4px 4px !important;
  }
  .vs__open-indicator {
    fill: #6045aa !important;
  }
  .vs__open-indicator:hover {
    fill: #7957d5 !important;
  }
  .vs__clear {
    fill: darkred !important;
  }
  .vs__clear:hover {
    fill: red !important;
  }
  .vs--single .vs__selected {
    color: white;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    overflow: hidden !important;
  }
  .vs__dropdown-option--selected {
    background-color: var(--main-color-bg-2);
  }
  .vs__dropdown-option--highlight {
    background: var(--main-color-bg-3) !important;
  }
  .vs_actions {
    cursor: pointer;
  }
  .vs__selected {
    background-color: var(--main-color-bg-1);
    color: white;
  }
  .vs__deselect {
    fill: darkred;
  }
  .vs__deselect:hover {
    fill: red;
  }
</style>

<style>
  .labelcolor-warning > .label {
    color: #ffdd57 !important
  }
  .help {
    color: white !important;
  }
  .hovermessagefeild:hover > .help {
    display: unset !important;
  }
  .hovermessagefeild > .help {
    display: none !important;
  }
  .is-invisible {
    display: none !important;
  }
  .has-shadow {
    box-shadow: 0 0.1rem 1.1rem rgba(0,0,0,.3)!important
  }
</style>

<style lang="scss" scoped>
.button.is-fullwidth {
  box-shadow: 0 0.1rem 1.1rem rgba(0,0,0,.3)!important;
}
.columns {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
#sectionPageMain {
  padding: 0.5rem 1.5rem !important;
  padding-bottom: 3rem !important;
}
.card {
  border-radius: 6px;
  border: 1px solid var(--main-color-bg-3);
  background: var(--main-color-bg);
  height: unset !important;
}
h3.subtitle.is-spaced {
  color: lightgray;
}
.p-1 {
  padding: 1em;
}
.sidebar-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    /* min-height: 100vh; */
    .sidebar-layout {
        display: flex;
        flex-direction: row;
        min-height: 100%;
        min-height: calc(100vh - ( 3.25rem - 0rem ) );
    }
}
</style>
