<template>
  <div>
    <div class="columns">
      <div :class="{'is-disabled': !guild.isManager || guild.guildDB.data.xpSyncStatus === true}" class="column is-half">
        <div class="card tile is-child has-shadow">
         <div class="card-header-title" style="display: block;">
            <p class="title is-4 has-text-white">Level Options:</p>
            <p class="subtitle is-6 has-text-white">To disable per channel enter <code style="background-color: var(--main-color-bg-2);">{{leveltagdisable}}</code> in channel topic</p>
          </div>
          <div class="card-content">
            <b-field class="hovermessagefeild" :message="'Current Status: ' + (guild.guildDB.data.xpEnable ? 'Enabled' : 'Disabled') " label="Leveling Status" for="xpstatus">
                <b-button id="xpstatus" @click="xpStatusUpdate" :key="guild.guildDB.data.xpEnable" v-bind:type="{'is-danger': (guild.guildDB.data.xpEnable ? true : false), 'is-success': (!guild.guildDB.data.xpEnable ? true : false)}" v-bind:label="(guild.guildDB.data.xpEnable ? 'Disable Leveling': 'Enable Leveling')" expanded></b-button>
            </b-field>
            <b-field label="Xp Reset" for="xpreset">
                <b-button id="xpreset" @click="xpUserReset" type='is-warning' label='Reset User Xp' expanded></b-button>
            </b-field>
            <b-field label="Add Level Role" for="leveladd">
                <b-button id="leveladd" @click="leveladdbtt" type='is-success' label='Add Level Role' expanded></b-button>
            </b-field>
            <b-field label="Transfer From Mee6" message="Once you click this, it can't be undone. Please read doc for more info." for="transfermee6">
                <b-button id="transfermee6" @click="mee6Transfer" :disabled="!!guild.guildDB.data.xpSyncDisable || !guild.owner || !guild.guildDB.data.xpEnable" type='is-info' label='Transfer Levels' expanded></b-button>
            </b-field>
          </div>
        </div>
      </div>
      <div :class="{'is-disabled': !guild.isManager || !guild.guildDB.data.premium || guild.guildDB.data.xpSyncStatus === true}" class="column is-half">
        <div class="card tile is-child has-shadow">
         <div class="card-header-title" style="display: block;">
            <p class="title is-4 has-text-white">XP Range: <b-tag v-if="!guild.guildDB.data.premium" type="is-warning">PREMIUM REQUIRED</b-tag></p>
            <p class="subtitle is-6 has-text-white">The range of xp a user will receive per message. <br>Default Range: <code style="background-color: var(--main-color-bg-2);">10, 30</code></p>
          </div>
          <div class="card-content">
            <b-field label="Select a range" for="xpRangeSlider">
              <b-slider id="xpRangeSlider" :disabled="xpRangeSliderDisabled" @change="xpRangeChange" size="is-medium" rounded type="is-info" :value="this.xpRanges" :min="0" :max="100" :step="5" ticks>
              </b-slider>
            </b-field>
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div :class="{'is-disabled': !guild.isManager || guild.guildDB.data.xpSyncStatus === true}" class="column is-half">
        <div class="card tile is-child has-shadow">
          <div class="card-header-title">
            <p class="title is-4 has-text-white">Level Extra Options:</p>
          </div>
          <div class="card-content">
            <b-field label="Level Roles" for="xprolelist">
              <div v-if="guild.guildDB.data.levelRoleUp.length <= 0">
                <img v-if="levelSearch" src="https://cdn.gracebot.net/grace/fileSearch.svg" style="height: 150px; padding-bottom: 10px;display: block; margin-left: auto; margin-right: auto; width: 50%;">
                <h1 class="title is-4 has-text-centered">No Level's Found!</h1>
                <h1 class="subtitle has-text-centered has-text-white" v-if="levelSearch">We searched far and wide but found nothing!</h1>
              </div>
              <div v-else>
                <div class="column is-multiline has-shadow" style="background-color: var(--main-color-bg-2); margin-bottom: 10px; border-radius: 6px;" v-for="level in guild.guildDB.data.levelRoleUp.map(g => { return {...g, discord: guild.bot.data.roles.find(f => f.id === g.roleID)} }).sort(function (a, b) {return Number(b.level) - Number(a.level)})" :key="(level.roleID + level.timeStamp)" :id="(level.roleID + level.timeStamp)">
                  <h4 class="title is-5 has-text-white Nunito">
                    <span class="has-text-grey-light" :style="`border-color: #${level.discord ? level.discord.color.toString(16).padStart(6, '0') : 'fff'};`" style="border: 1.2px solid; width:100%; border-radius: 15px;padding: 0px 20px;font-size: 15px;text-align: center;">
                      {{ level.discord ? (level.discord.name == '@everyone' ? '@everyone' : '@' + level.discord.name) : level.roleID }}
                    </span>
                    <b-dropdown position="is-bottom-left" aria-role="list" style="margin-left: 5px;float: right!important;">
                      <a style="float: right!important;color:white;" slot="trigger" role="button">
                        <i class="fas fa-ellipsis-v fa-sm"></i>
                      </a>
                      <b-dropdown-item aria-role="listitem" @click="$copyToClipboard(level.roleID)">Copy ID</b-dropdown-item>
                      <b-dropdown-item aria-role="listitem" @click="leveldelete(level.roleID, (level.roleID + level.timeStamp))">Delete Level</b-dropdown-item>
                    </b-dropdown>
                    <span style="float:right; font-size: 20px;">Level {{ level.level }}</span>
                  </h4>
                </div>
              </div>
            </b-field>
            <b-field class="hovermessagefeild" :message="['%user:ping% = The user leveling up but pinged.', '%user:text% = The user leveling up but username#number.', '%user:id% = The user id that is leveling up.', '%user:levelup% = The user level up number.', '%user:levelbefore% = The user level before the levelup.', '%guild:name% = The name of the guild.', '%user:levelbefore% = The id of the guild.', '%newline% = Creates a new line (or press enter).', 'Delete text for default message!']" label="Level Up Message" for="levelUpMessage">
              <b-input id="levelUpMessage" placeholder="Enter a level up message OR leave empty for default." @focusout.native="undbindELTarJquery" @focus="levelUpMessage" custom-class="input-default" :value="(guild.guildDB.data.levelUpMessage ? guild.guildDB.data.levelUpMessage : '').replace(/%newline%/ig, '\n')" type="textarea" maxlength="500"></b-input>
            </b-field>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import 'vue-select/dist/vue-select.css'
  // import JsColor from '@/components/JsColor.vue'
  // import vSelect from 'vue-select'
  import { createPopper } from '@popperjs/core'

  export default {
    name: 'Level',
    components: {
      // JsColor,
      // vSelect
    },
    props: ['guildData'],
    computed: {
      guild: function () {
        return this.guildData
      }
    },
    data () {
      return {
        SelectPlacement: 'bottom',
        levelSearch: Math.random() <= 0.2,
        xpRanges: [10, 30],
        xpRangeSliderDisabled: false,
        leveltagdisable: '<Grace:disableLevel>'
      }
    },
    created () {
      this.xpRanges = [this.guild.guildDB.data.xpPerMessage1, this.guild.guildDB.data.xpPerMessage2]
    },
    methods: {
      getGuild () {
        this.$parent.getGuild()
      },
      Slashremove (str = '') {
        return (str + '').replace(/\\(.?)/g, function (s, n1) {
          switch (n1) {
          case '\\':
            return '\\'
          case '0':
            return '\u0000'
          case '':
            return ''
          default:
            return n1
          }
        })
      },
      Slashadd (str = '') {
        return (str + '').replace(/[\\"']/g, '\\$&').replace(/\u0000/g, '\\0')
      },
      vselectfixer (dropdownList, component, { width }) {
        dropdownList.style.width = width
        const popper = createPopper(component.$refs.toggle, dropdownList, {
          placement: this.SelectPlacement,
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -1]
              }
            },
            {
              name: 'toggleClass',
              enabled: true,
              phase: 'write',
              fn ({ state }) {
                component.$el.classList.toggle('drop-up', state.SelectPlacement === 'top')
              }
            }
          ]
        })
        return () => popper.destroy()
      },
      undbindELTarJquery (el) {
        this.$(el.target).unbind()
      },
      xpRangeChange (newXpRange) {
        this.xpRangeSliderDisabled = true
        this.$store.dispatch('patchApi', { path: `guild/${this.$route.params.id}/xpranges`, body: { ranges: newXpRange }, headers: {}, params: {} }).then(async (result) => {
          this.$buefy.snackbar.open({
            message: 'Xp Range Updated!',
            type: 'is-success',
            position: 'is-bottom-right'
          })
          await this.$store.dispatch('sleep', 2000)
          this.xpRangeSliderDisabled = false
          this.getGuild()
        }).catch(async (error) => {
          console.log(error, 'Error')
          this.$buefy.snackbar.open({
            message: 'Oh no! Some error happened!',
            type: 'is-danger',
            position: 'is-bottom-right'
          })
          this.xpRanges = [this.guild.guildDB.data.xpPerMessage1, this.guild.guildDB.data.xpPerMessage2]
          await this.$store.dispatch('sleep', 2000)
          this.xpRangeSliderDisabled = false
          this.getGuild()
        })
      },
      async xpUserReset (el) {
        var _this = this
        el = this.$(el.target)
        el.prop('disabled', true)
        let value1 = null
        const menu = this.$swal.mixin({
          confirmButtonText: 'Next &rarr;',
          showCancelButton: true,
          progressSteps: ['1', '2'],
          footer: '<p><a href="https://support.discordapp.com/hc/en-us/articles/206346498-Where-can-I-find-my-User-Server-Message-ID-">Click Here</a> to learn how to get a user id.</p>'
        })
        await menu.fire({
          title: 'Please enter user id!',
          text: 'Example: 604170962178408469',
          input: 'text',
          showLoaderOnConfirm: true,
          preConfirm: async (data) => {
            return new Promise((resolve, reject) => {
              if (!data || data === undefined || data === null || /[0-9]/.test(data) === false) return reject(new Error('Please insert id!'))
              resolve()
            }).catch(error => {
              this.$swal.showValidationMessage(`Request failed: ${error}<br>Please try again!`)
            })
          },
          inputAttributes: {}
        }).then((result) => {
          if (result.value) value1 = result.value
        })
        if (value1) {
          await menu.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to undo this!',
            icon: 'warning',
            showLoaderOnConfirm: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
          }).then((result) => {
            if (value1 && result.isConfirmed) {
              const snackbar = this.$buefy.snackbar.open({
                message: 'Deleteing Member, please wait...',
                type: 'is-warning',
                position: 'is-bottom-right',
                indefinite: true,
                actionText: null
              })
              this.$store.dispatch('deleteApi', { path: `guild/${this.$route.params.id}/levelMember/${value1}/`, body: { id: value1 /* Just incase put id in body if we choose to change it we can */ }, headers: {}, params: {} }).then(async (result) => {
                snackbar.close()
                this.$buefy.snackbar.open({
                  message: 'Level Member delete!',
                  type: 'is-success',
                  position: 'is-bottom-right'
                })
                el.prop('disabled', false)
                await this.$store.dispatch('sleep', 2000)
                this.getGuild()
              }).catch(async (error) => {
                console.log(error, 'Error')
                snackbar.close()
                this.$buefy.snackbar.open({
                  message: 'Oh no! Some error happened!',
                  type: 'is-danger',
                  position: 'is-bottom-right'
                })
                el.prop('disabled', false)
                await this.$store.dispatch('sleep', 2000)
                this.getGuild()
              })
            } else {
              el.prop('disabled', false)
            }
          })
        }
      },
      async leveladdbtt (el) {
        var _this = this
        el = this.$(el.target)
        el.prop('disabled', true)
        let value1 = null
        const menu = this.$swal.mixin({
          input: 'text',
          confirmButtonText: 'Next &rarr;',
          showCancelButton: true,
          progressSteps: ['1', '2'],
          footer: 'The bot role must be above the role to be able to add it to the server'
        })
        await menu.fire({
          title: 'Please enter a level number!',
          text: 'Example: 15',
          input: 'number',
          inputAttributes: {
            min: 0,
            max: 5000
          },
          showLoaderOnConfirm: true,
          preConfirm: async (data) => {
            return new Promise((resolve, reject) => {
              if (!data || data === undefined || data === null) return reject(new Error('Please insert number!'))
              resolve()
            }).catch(error => {
              this.$swal.showValidationMessage(`Request failed: ${error}<br>Please try again!`)
            })
          }
        }).then((result) => {
          if (result.value) value1 = result.value
        })
        if (value1) {
          await menu.fire({
            title: 'Please select role!',
            text: 'Example: @role',
            input: 'select',
            inputPlaceholder: 'Select a role.',
            preConfirm: async (data) => {
              return new Promise((resolve, reject) => {
                if (!data || data === undefined || data === null) return reject(new Error('Please select role!'))
                resolve()
              }).catch(error => {
                this.$swal.showValidationMessage(`Request failed: ${error}<br>Please try again!`)
              })
            },
            inputOptions: new Promise((resolve, reject) => {
              this.$swal.showLoading()
              const inputData = {}
              this.guild.bot.data.roles.filter(g => g.managed === false).filter(f => f.name !== '@everyone').forEach(async (item, index) => {
                inputData[item.id] = item.name
              })
              this.$swal.hideLoading()
              resolve(inputData)
            }).catch(error => { console.log(error) })
          }).then((result) => {
            if (result.value && value1) {
              console.log(result)
              const snackbar = this.$buefy.snackbar.open({
                message: 'Adding Level, please wait...',
                type: 'is-warning',
                position: 'is-bottom-right',
                indefinite: true,
                actionText: null
              })
              this.$store.dispatch('postApi', { path: `guild/${this.$route.params.id}/levelRoles`, body: { level: value1, role: result.value }, headers: {}, params: {} }).then(async (result) => {
                snackbar.close()
                this.$buefy.snackbar.open({
                  message: 'Level Role added!',
                  type: 'is-success',
                  position: 'is-bottom-right'
                })
                el.prop('disabled', false)
                await this.$store.dispatch('sleep', 2000)
                this.getGuild()
              }).catch(async (error) => {
                console.log(error, 'Error')
                snackbar.close()
                this.$buefy.snackbar.open({
                  message: 'Oh no! Some error happened!',
                  type: 'is-danger',
                  position: 'is-bottom-right'
                })
                el.prop('disabled', false)
                await this.$store.dispatch('sleep', 2000)
                this.getGuild()
              })
            } else {
              el.prop('disabled', false)
            }
          })
        }
      },
      leveldelete (id, IdTimeStamp) {
        this.$store.dispatch('deleteApi', { path: `guild/${this.$route.params.id}/levelRoles/${id}`, body: { id: id }, headers: {}, params: {} }).then(async (result) => {
          this.$buefy.snackbar.open({
            message: 'Level Role Deleted!',
            type: 'is-success',
            position: 'is-bottom-right'
          })
          await this.$store.dispatch('sleep', 2000)
          this.getGuild()
        }).catch(async (error) => {
          console.log(error, 'Error')
          this.$buefy.snackbar.open({
            message: 'Oh no! Some error happened!',
            type: 'is-danger',
            position: 'is-bottom-right'
          })
          await this.$store.dispatch('sleep', 2000)
          this.getGuild()
        })
      },
      levelUpMessage (el) {
        var _this = this
        el = this.$(el.target)
        el.data('valbefore', el.val())
        el.donetyping(function (callback) {
          var value = el.val()
          if (el.data('valbefore') === value) return
          el.prop('disabled', true)
          _this.$store.dispatch('patchApi', { path: `guild/${_this.$route.params.id}/levelMessage`, body: { message: value }, headers: {}, params: {} }).then(async (result) => {
            el.val(value)
            _this.$buefy.snackbar.open({
              message: 'Level Message was updated!',
              type: 'is-success',
              position: 'is-bottom-right'
            })
            el.prop('disabled', false)
            await _this.$store.dispatch('sleep', 2000)
            _this.getGuild()
          }).catch(async (error) => {
            console.log(error, 'er')
            el.val(el.data('valbefore'))
            _this.$buefy.snackbar.open({
              message: 'Oh no! Some error happened!',
              type: 'is-danger',
              position: 'is-bottom-right'
            })
            el.prop('disabled', false)
            await _this.$store.dispatch('sleep', 2000)
            _this.getGuild()
          })
        })
      },
      mee6Transfer (el) {
        var _this = this
        el = this.$(el.target)
        el.prop('disabled', true)
        const updateVal = this.guild.guildDB.data.xpSyncStatus ? false : true || false
        console.log(updateVal)
        this.$store.dispatch('postApi', { path: `guild/${this.$route.params.id}/mee6transfer`, body: { start: true }, headers: {}, params: {} }).then(async (result) => {
          this.$buefy.snackbar.open({
            message: 'Mee6 Transfer has started!<br><a href="https://docs.gracebot.net/v/website/how-to-import-levels-from-mee6">Click Here For More Info!</a>',
            type: 'is-success',
            position: 'is-bottom-right'
          })
          this.guild.guildDB.data.xpEnable = Number(updateVal)
          el.prop('disabled', false)
          await this.$store.dispatch('sleep', 2000)
          this.getGuild()
        }).catch(async (error) => {
          console.log(error, 'Error')
          this.$buefy.snackbar.open({
            message: 'Oh no! Some error happened!<br><a href="https://docs.gracebot.net/v/website/how-to-import-levels-from-mee6">Click Here For More Info!</a>',
            type: 'is-danger',
            position: 'is-bottom-right'
          })
          el.prop('disabled', false)
          await this.$store.dispatch('sleep', 2000)
          this.getGuild()
        })
      },
      xpStatusUpdate (el) {
        var _this = this
        el = this.$(el.target)
        el.prop('disabled', true)
        const updateVal = this.guild.guildDB.data.xpEnable ? false : true || false
        console.log(updateVal)
        this.$store.dispatch('patchApi', { path: `guild/${this.$route.params.id}/xpstatus`, body: { status: updateVal }, headers: {}, params: {} }).then(async (result) => {
          this.$buefy.snackbar.open({
            message: 'Commands was updated!',
            type: 'is-success',
            position: 'is-bottom-right'
          })
          this.guild.guildDB.data.xpEnable = Number(updateVal)
          el.prop('disabled', false)
          await this.$store.dispatch('sleep', 2000)
          this.getGuild()
        }).catch(async (error) => {
          console.log(error, 'Error')
          this.$buefy.snackbar.open({
            message: 'Oh no! Some error happened!',
            type: 'is-danger',
            position: 'is-bottom-right'
          })
          el.prop('disabled', false)
          await this.$store.dispatch('sleep', 2000)
          this.getGuild()
        })
      }
    }
  }
</script>

<style>
  .vs--disabled .vs__dropdown-toggle, .vs--disabled .vs__clear, .vs--disabled .vs__search, .vs--disabled .vs__selected, .vs--disabled .vs__open-indicator {
    background: var(--main-color-bg-2);
    cursor: not-allowed;
    color: #7a7a7a !important;
  }
  .vs__dropdown-toggle {
    background: var(--main-color-bg-2);
    color: white !important;
    border-color: var(--main-color-bg-3) !important;
    min-height: 2.25em !important;
    height: 100% !important;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: 0 0.1rem 1.1rem rgba(0,0,0,.3) !important;
  }
  .vs__dropdown-menu {
    background: var(--main-color-bg-1);
    color: white;
    border-color: var(--main-color-bg-3) !important;
    border-top-style: unset !important;
    border-radius: 4px 4px 4px 4px !important;
  }
  .vs__open-indicator {
    fill: #6045aa !important;
  }
  .vs__open-indicator:hover {
    fill: #7957d5 !important;
  }
  .vs__clear {
    fill: darkred !important;
  }
  .vs__clear:hover {
    fill: red !important;
  }
  .vs--single .vs__selected {
    color: white;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    overflow: hidden !important;
  }
  .vs__dropdown-option--selected {
    background-color: var(--main-color-bg-2);
  }
  .vs__dropdown-option--highlight {
    background: var(--main-color-bg-3) !important;
  }
  .vs_actions {
    cursor: pointer;
  }
  .vs__selected {
    background-color: var(--main-color-bg-1);
    color: white;
  }
  .vs__deselect {
    fill: darkred;
  }
  .vs__deselect:hover {
    fill: red;
  }
</style>

<style>
  .labelcolor-warning > .label {
    color: #ffdd57 !important
  }
  .help {
    color: white !important;
  }
  .hovermessagefeild:hover > .help {
    display: unset !important;
  }
  .hovermessagefeild > .help {
    display: none !important;
  }
  .is-invisible {
    display: none !important;
  }
  .has-shadow {
    box-shadow: 0 0.1rem 1.1rem rgba(0,0,0,.3)!important
  }
</style>

<style lang="scss" scoped>

  .button.is-fullwidth {
    box-shadow: 0 0.1rem 1.1rem rgba(0,0,0,.3)!important;
  }
  .columns {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  #sectionPageMain {
    padding: 0.5rem 1.5rem !important;
    padding-bottom: 3rem !important;
  }
  .card {
    border-radius: 6px;
    border: 1px solid var(--main-color-bg-3);
    background: var(--main-color-bg);
    height: unset !important;
  }
  h3.subtitle.is-spaced {
    color: lightgray;
  }
  .p-1 {
    padding: 1em;
  }
  .sidebar-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    /* min-height: 100vh; */
    .sidebar-layout {
        display: flex;
        flex-direction: row;
        min-height: 100%;
        min-height: calc(100vh - ( 3.25rem - 0rem ) );
    }
  }
</style>
