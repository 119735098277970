<template>
  <div>
    <div class="columns">
      <div v-if="guild.guildDB.data.beta && guild.id === '469387242767646730'" :class="{'is-disabled': !guild.isManager || !guild.guildDB.data.premium || !guild.guildDB.data.beta}" class="column is-half">
        <div class="card tile is-child has-shadow">
          <div class="card-header-title" style="display: block;">
            <p class="title is-4 has-text-white">Custom Bot: <b-tag class="" type="is-danger">BETA</b-tag> <b-tag v-if="!guild.guildDB.data.premium" type="is-warning">PREMIUM REQUIRED</b-tag></p>
            <p class="subtitle is-6 has-text-white">Profile Image must follow Discord TOS & T&S!</p>
          </div>
          <div class="card-content">
            <b-field label="Upload Profile (Image will be resized by discord)" for="customProfile">
              <img class="icon-img" style="width: 128px !important; height: 128px !important; margin-left: 20px; border-radius: 100px; border-color: var(--main-color-bg-3); border-width: 2px; border-style: solid; background-color: var(--main-color-bg-2) !important;" :src="image" :onerror="'this.src=\'https://cdn.discordapp.com/embed/avatars/0.png?size=128\';'">
            </b-field>
            <b-field class="file">
              <b-upload v-model="fileUpload" @input="changeImage" accept="image/jpg,image/jpeg,image/png,image/webp">
                <a class="button is-primary is-fullwidth">
                  <b-icon icon="upload"></b-icon>
                  <span>{{ fileUpload.name || "Click to upload"}}</span>
                </a>
              </b-upload>
            </b-field>
            <b-field label="Name" for="customBotName">
              <b-input :value="guild.guildDB.data.customBotName" @focusout.native="undbindELTarJquery" id="customBotName" placeholder="Enter Name" maxlength="70" custom-class="input-default"></b-input>
            </b-field>
            <b-field class="hovermessagefeild" :message="'Current Status: ' + (guild.guildDB.data.customBot ? 'Enabled' : 'Disabled') " label="Enable Custom Bot" for="customBotStatus">
              <b-button id="customBotStatus" :key="guild.guildDB.data.customBot" v-bind:type="{'is-danger': (guild.guildDB.data.customBot ? true : false), 'is-success': (!guild.guildDB.data.customBot ? true : false)}" v-bind:label="(guild.guildDB.data.customBot ? 'Disable': 'Enable')" expanded></b-button>
            </b-field>
          </div>
        </div>
      </div>
      <div :class="{'is-disabled': !guild.isManager}" class="column is-half">
        <div class="card tile is-child has-shadow">
          <div class="card-header-title" style="display: block;">
            <p class="title is-4 has-text-white">Language:</p>
            <p class="subtitle is-6 has-text-white">Select a default language for Grace!</p>
          </div>
          <div class="card-content">
            <b-field label="Select Language" for="selLang">
              <v-select @input="guildLang" :disabled="guildLangDisabled" appendToBody :selectOnTab="true" placeholder="Select a Language!" :calculatePosition="vselectfixer" autocomplete :searchable="true" required :clearable="false" :getOptionLabel="option => `${(this.talk.of(option) ? this.talk.of(option) : option)}`" :options="langs" :value="guild.guildDB.data.language">
                <template slot="option" slot-scope="option" style="width: 100%">
                  <div class="" style="padding-right: 0px !important;color:white!important; padding-top: 10px; padding-bottom: 10px; width:100%">
                    <span class="Nunito" :style="`color: white;`">{{ talk.of(option.label) ? talk.of(option.label) : option.label }}</span>
                  </div>
                </template>
              </v-select>
            </b-field>
          </div>
        </div>
      </div>
      <div :class="{'is-disabled': !guild.isManager}" class="column is-half">
        <div class="card tile is-child has-shadow">
          <div class="card-header-title" style="display: block;">
            <p class="title is-4 has-text-white">Language:</p>
            <p class="subtitle is-6 has-text-white">Select a default language for Grace!</p>
          </div>
          <div class="card-content">
            <b-field label="Select Language" for="selLang">
              <v-select @input="guildLang" :disabled="guildLangDisabled" appendToBody :selectOnTab="true" placeholder="Select a Language!" :calculatePosition="vselectfixer" autocomplete :searchable="true" required :clearable="false" :getOptionLabel="option => `${(this.talk.of(option) ? this.talk.of(option) : option)}`" :options="langs" :value="guild.guildDB.data.language">
                <template slot="option" slot-scope="option" style="width: 100%">
                  <div class="" style="padding-right: 0px !important;color:white!important; padding-top: 10px; padding-bottom: 10px; width:100%">
                    <span class="Nunito" :style="`color: white;`">{{ talk.of(option.label) ? talk.of(option.label) : option.label }}</span>
                  </div>
                </template>
              </v-select>
            </b-field>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import 'vue-select/dist/vue-select.css'
  import vSelect from 'vue-select'
  import { createPopper } from '@popperjs/core'
  import { allowLangs } from 'locales'

  export default {
    name: 'customize',
    components: {
      // JsColor,
      vSelect
    },
    props: ['guildData'],
    computed: {
      guild: function () {
        return this.guildData
      }
    },
    data () {
      return {
        SelectPlacement: 'bottom',
        guildLangDisabled: false,
        langs: allowLangs,
        // langs: Object.keys({ en: { }, da: { } }),
        talk: new Intl.DisplayNames(['en'], { type: 'language' }),
        fileUpload: {},
        image: 'https://cdn.discordapp.com/embed/avatars/0.png'
      }
    },
    created () {
    },
    methods: {
      getGuild () {
        this.$parent.getGuild()
        this.guild.guildDB.data.customBotImage = null
        this.image = (this.guild.guildDB.data.customBotImage ? this.guild.guildDB.data.customBotImage : 'https://cdn.discordapp.com/embed/avatars/0.png')
      },
      undbindELTarJquery (el) {
        this.$(el.target).unbind()
      },
      changeImage (value) {
        console.log(value)
        var reader = new FileReader()
        reader.readAsDataURL(value)
        reader.onload = () => this.startImageUpload(reader.result)
        reader.onerror = function (error) {
          console.log('Error: ', error)
        }
      },
      startImageUpload (data) {
        this.guild.guildDB.data.customBotImage = data
        this.image = data
        console.log(this.guild.guildDB.data.customBotImage, 'guild.guildDB.data.customBotImage')
      },
      vselectfixer (dropdownList, component, { width }) {
        dropdownList.style.width = width
        const popper = createPopper(component.$refs.toggle, dropdownList, {
          placement: this.SelectPlacement,
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -1]
              }
            },
            {
              name: 'toggleClass',
              enabled: true,
              phase: 'write',
              fn ({ state }) {
                component.$el.classList.toggle('drop-up', state.SelectPlacement === 'top')
              }
            }
          ]
        })
        return () => popper.destroy()
      },
      guildLang (value) {
        console.log(value)
        const beforeUpdate = this.guild.guildDB.data.language
        this.guildLangDisabled = true
        this.guild.guildDB.data.language = value
        this.$store.dispatch('patchApi', { path: `guild/${this.$route.params.id}/lang`, body: { lang: value }, headers: {}, params: {} }).then(async (result) => {
          this.$buefy.snackbar.open({
            message: 'Default Bot Language was updated!',
            type: 'is-success',
            position: 'is-bottom-right'
          })
          this.guildLangDisabled = false
          await this.$store.dispatch('sleep', 2000)
          this.getGuild()
        }).catch(async (error) => {
          console.log(error, 'er')
          this.$buefy.snackbar.open({
            message: 'Oh no! Some error happened!',
            type: 'is-danger',
            position: 'is-bottom-right'
          })
          this.guild.guildDB.data.language = beforeUpdate
          this.guildLangDisabled = false
          await this.$store.dispatch('sleep', 2000)
          this.getGuild()
        })
      }
    }
  }
</script>

<style>
  .vs--disabled .vs__dropdown-toggle, .vs--disabled .vs__clear, .vs--disabled .vs__search, .vs--disabled .vs__selected, .vs--disabled .vs__open-indicator {
    background: var(--main-color-bg-2);
    cursor: not-allowed;
    color: #7a7a7a !important;
  }
  .vs__dropdown-toggle {
    background: var(--main-color-bg-2);
    color: white !important;
    border-color: var(--main-color-bg-3) !important;
    min-height: 2.25em !important;
    height: 100% !important;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: 0 0.1rem 1.1rem rgba(0,0,0,.3) !important;
  }
  .vs__dropdown-menu {
    background: var(--main-color-bg-1);
    color: white;
    border-color: var(--main-color-bg-3) !important;
    border-top-style: unset !important;
    border-radius: 4px 4px 4px 4px !important;
  }
  .vs__open-indicator {
    fill: #6045aa !important;
  }
  .vs__open-indicator:hover {
    fill: #7957d5 !important;
  }
  .vs__clear {
    fill: darkred !important;
  }
  .vs__clear:hover {
    fill: red !important;
  }
  .vs--single .vs__selected {
    color: white;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    overflow: hidden !important;
  }
  .vs__dropdown-option--selected {
    background-color: var(--main-color-bg-2);
  }
  .vs__dropdown-option--highlight {
    background: var(--main-color-bg-3) !important;
  }
  .vs_actions {
    cursor: pointer;
  }
  .vs__selected {
    background-color: var(--main-color-bg-1);
    color: white;
  }
  .vs__deselect {
    fill: darkred;
  }
  .vs__deselect:hover {
    fill: red;
  }
</style>

<style>
  .labelcolor-warning > .label {
    color: #ffdd57 !important
  }
  .help {
    color: white !important;
  }
  .hovermessagefeild:hover > .help {
    display: unset !important;
  }
  .hovermessagefeild > .help {
    display: none !important;
  }
  .is-invisible {
    display: none !important;
  }
  .has-shadow {
    box-shadow: 0 0.1rem 1.1rem rgba(0,0,0,.3)!important
  }
</style>

<style lang="scss" scoped>
.button.is-fullwidth {
  box-shadow: 0 0.1rem 1.1rem rgba(0,0,0,.3)!important;
}
.columns {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
#sectionPageMain {
  padding: 0.5rem 1.5rem !important;
  padding-bottom: 3rem !important;
}
.card {
  border-radius: 6px;
  border: 1px solid var(--main-color-bg-3);
  background: var(--main-color-bg);
  height: unset !important;
}
h3.subtitle.is-spaced {
  color: lightgray;
}
.p-1 {
  padding: 1em;
}
.sidebar-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    /* min-height: 100vh; */
    .sidebar-layout {
        display: flex;
        flex-direction: row;
        min-height: 100%;
        min-height: calc(100vh - ( 3.25rem - 0rem ) );
    }
}
</style>
