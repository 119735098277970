<template>
  <div>
    <div class="columns is-centered is-mobile">
      <div :class="{'is-disabled': (!guild.isManager || actionAdding || guild.guildDB.data.customActions.length >= (guild.guildDB.data.premium ? Infinity : 15) )}" class="column is-full has-text-centered">
        <b-button :disabled="actionAdding" expanded type="is-info" v-on:click="showActionAdd = !showActionAdd">Add Custom Action ({{ guild.guildDB.data.customActions.length }}/{{ guild.guildDB.data.premium ? '∞' : 15 }})</b-button>
      </div>
    </div>
    <AddAction v-if="showActionAdd || actionAdding" :guildData="guild ? guild : null" />
    <div class="columns is-centered" style="padding-top: 0.5rem !important;">
      <div class="column is-centered">
        <h3 v-if="guild.guildDB.data.customActions.length <= 0" class="title is-3" style="text-align: center;">Add a custom action!</h3>
        <div class="container" v-else>
          <div class="columns is-mobile is-centered is-multiline">
            <div class="column action-column has-text-centered is-full" v-for="action in guild.guildDB.data.customActions" :id="action.id" :key="action.id">
              <b-collapse animation="slide" :open="false" class="card action" :aria-id="'aria' + action.id">
                <div slot="trigger" slot-scope="props" :aria-controls="'aria' + action.id" class="card-header action-header">
                  <p class="card-header-title action-header-title">
                    <b-tag id="action-status-title-tag" ellipsis :type="(action.active ? 'is-success' : 'is-danger')">{{(action.active ? 'Enabled' : 'Disabled')}}</b-tag>
                    <b-tag id="action-status-title-tag-2" style="display: none;" ellipsis :type="(action.active ? 'is-success' : 'is-danger')">{{(action.active ? 'E' : 'D')}}</b-tag>
                    <span style="padding-left: 10px; margin-bottom:0px;" class="title is-5 has-text-white Nunito">{{ actionType(action.actionType) }}</span>
                    <span id="action-status-title-tag" style="padding-left: 10px;font-size: 13px;margin-left: -3px;margin-bottom:-5px;" class="has-text-white Nunito">{{ $MomentJS((action.dateCreated * 1000)).format("[Created at,] dddd, MMMM Do YYYY") }}</span>
                  </p>
                  <a class="card-header-icon">
                    <b-icon :icon="props.open ? 'caret-up' : 'caret-down'"> </b-icon>
                    <button class="delete action-delete" @click.stop="deleteAction($event, props, action.id, action)"></button>
                  </a>
                </div>
                <div class="card-content">
                  <div class="content">
                    <div class="columns is-mobile is-centered is-multiline">
                      <div class="column has-text-centered is-half">
                        <b-field class="hovermessagefeild" :message="'Current Status: ' + (action.active ? 'Enabled' : 'Disabled') " :label="`${(action.active ? 'Disable ': 'Enable ')} ${actionType(action.actionType)}`">
                          <b-button @click="disableAction($event, action.id, action)" :key="action.active" v-bind:type="{'is-danger': (action.active ? true : false), 'is-success': (!action.active ? true : false)}" v-bind:label="(action.active ? 'Disable Action': 'Enable Action')" expanded></b-button>
                        </b-field>
                      </div>
                      <div class="column has-text-centered is-half" v-if="action.actionType === 'reactionEmoji'">
                        <b-field label="Please Note.">
                          Reaction Roles might take up to 5 mins to load as cache expires, shard reboot, or was just added.
                        </b-field>
                      </div>
                      <div class="column has-text-centered is-half" v-else-if="action.actionType === 'tag' || action.actionType === 'automessage'">
                        <b-field label="Message Content PlaceHolders">
                          <p>
                            <code style="background-color: var(--main-color-bg-2);">%user:ping%</code> = Ping User<br><code style="background-color: var(--main-color-bg-2);">%user:text%</code> = User Tag<br><code style="background-color: var(--main-color-bg-2);">%user:id%</code> = User id<br><code style="background-color: var(--main-color-bg-2);">%guild:name%</code> = Guild/Server Name<br><code style="background-color: var(--main-color-bg-2);">%guild:id%</code> = Guild/Server id<br><code style="background-color: var(--main-color-bg-2);">%newline%</code> = New line OR (press shift enter)
                          </p>
                        </b-field>
                      </div>
                      <div v-else class="column has-text-centered is-half"></div>
                    </div>
                    <div class="columns is-mobile is-centered is-multiline" v-if="action.actionType === 'reactionEmoji'">
                      <div class="column has-text-centered is-half">
                        <b-field class="hovermessagefeild" label="Emoji">
                          <img v-if="guild.bot.data.emojis.find(g => g.id === action.emojiReactionID)" :src="`${$store.getters.admin.discord.cdn}emojis/${action.emojiReactionID}.${guild.bot.data.emojis.find(g => g.id === action.emojiReactionID).animated ? 'gif' : 'png'}?size=64`" />
                          <span style="font-size:32px" v-else>{{ action.emojiReactionID }}</span>
                        </b-field>
                      </div>
                      <div class="column has-text-centered is-half">
                        <b-field class="hovermessagefeild" label="Role">
                          <span class="has-text-grey-light" :style="`border-color: #${guild.bot.data.roles.find(g => g.id === action.roleID) ? guild.bot.data.roles.find(g => g.id === action.roleID).color.toString(16).padStart(6, '0') : '000'};`" style="border: 1.2px solid; width:100%; border-radius: 15px;padding: 0px 20px;font-size: 15px;text-align: center;">
                            {{ guild.bot.data.roles.find(g => g.id === action.roleID) ? (guild.bot.data.roles.find(g => g.id === action.roleID).name == '@everyone' ? '@everyone' : '@' + guild.bot.data.roles.find(g => g.id === action.roleID).name) : 'DELETED ROLE' }}
                          </span>
                        </b-field>
                      </div>
                    </div>
                    <div class="columns is-mobile is-centered is-multiline" v-if="action.actionType === 'reactionEmoji' || action.actionType === 'automessage'">
                      <div class="column has-text-centered is-half">
                        <b-field class="hovermessagefeild" label="Channel">
                          <a v-if="guild.bot.data.channels.find(g => g.id === action.channelID)" :href="$store.getters.admin.discord.url + 'channels/' + guild.id + '/' + action.channelID">#{{guild.bot.data.channels.find(g => g.id === action.channelID).name}}</a>
                          <span v-else>DELETED CHANNEL</span>
                        </b-field>
                      </div>
                      <div class="column has-text-centered is-half" v-if="action.actionType !== 'automessage'">
                        <b-field class="hovermessagefeild" label="Message id">
                          <a :href="$store.getters.admin.discord.url + 'channels/' + guild.id + '/' + action.channelID + '/' + action.reactionMessageID">{{ action.reactionMessageID }}</a>
                        </b-field>
                      </div>
                    </div>
                    <div class="columns is-mobile is-centered is-multiline" v-if="action.actionType === 'tag'">
                      <div class="column has-text-centered is-half">
                        <b-field class="hovermessagefeild" label="Command Name">
                          <b-input style="color: white;" @focusout.native="undbindELTarJquery" onkeypress="return event.charCode != 32" @focus="updateInput($event, 'commandName', action.id, action)" custom-class="input-default" :value="action.commandName" type="text"></b-input>
                        </b-field>
                      </div>
                      <div class="column has-text-centered is-half">
                        <b-field class="hovermessagefeild" label="Content">
                          <b-input style="color: white;" custom-class="input-default" @focusout.native="undbindELTarJquery" @focus="updateInput($event, 'tagMessage', action.id, action)"  :value="action.tagMessage.replace(/%newline%/ig, '\n')" type="textarea"></b-input>
                        </b-field>
                      </div>
                    </div>
                    <div class="columns is-mobile is-centered is-multiline" v-if="action.actionType === 'automessage'">
                      <div class="column has-text-centered is-half">
                        <b-field :label="'Repeat every X mins'">
                          <b-slider :value="action.extra.time" @change="updateInputLy($event, 'automessageTime', action.id, action)" size="is-medium" :step="5" :min="5" :max="60" ticks>
                          </b-slider>
                        </b-field>
                      </div>
                      <div class="column has-text-centered is-half">
                        <b-field class="hovermessagefeild" label="Content">
                          <b-input style="color: white;" custom-class="input-default" @focusout.native="undbindELTarJquery" @focus="updateInput($event, 'automessageContent', action.id, action)"  :value="action.extra.content.replace(/%newline%/ig, '\n')" type="textarea"></b-input>
                        </b-field>
                      </div>
                    </div>
                    <div class="columns is-mobile is-centered is-multiline" v-if="action.actionType === 'commandRole'">
                      <div class="column has-text-centered is-half">
                        <b-field class="hovermessagefeild" label="Command Name">
                          <b-input style="color: white;" custom-class="input-default" @focusout.native="undbindELTarJquery" onkeypress="return event.charCode != 32" @focus="updateInput($event, 'commandName', action.id, action)"  :value="action.commandName" type="text"></b-input>
                        </b-field>
                      </div>
                      <div class="column has-text-centered is-half">
                        <b-field class="hovermessagefeild" label="Role">
                          <span class="has-text-grey-light" :style="`border-color: #${guild.bot.data.roles.find(g => g.id === action.roleID) ? guild.bot.data.roles.find(g => g.id === action.roleID).color.toString(16).padStart(6, '0') : '000'};`" style="border: 1.2px solid; width:100%; border-radius: 15px;padding: 0px 20px;font-size: 15px;text-align: center;">
                            {{ guild.bot.data.roles.find(g => g.id === action.roleID) ? (guild.bot.data.roles.find(g => g.id === action.roleID).name == '@everyone' ? '@everyone' : '@' + guild.bot.data.roles.find(g => g.id === action.roleID).name) : 'DELETED ROLE' }}
                          </span>
                        </b-field>
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import 'vue-select/dist/vue-select.css'
  // import JsColor from '@/components/JsColor.vue'
  // import vSelect from 'vue-select'
  import { createPopper } from '@popperjs/core'
  import AddAction from '@/components/addAction.vue'

  export default {
    name: 'actions',
    components: {
      AddAction
    },
    props: ['guildData'],
    computed: {
      guild: function () {
        return this.guildData
      }
    },
    data () {
      return {
        SelectPlacement: 'bottom',
        chunkArray: function (myArray, chunksize) {
          var index = 0
          var arrayLength = myArray.length
          var tempArray = []
          for (index = 0; index < arrayLength; index += chunksize) {
            const myChunk = myArray.slice(index, index + chunksize)
            tempArray.push(myChunk)
          }
          return tempArray
        },
        showActionAdd: false,
        actionAdding: false
      }
    },
    created () {
    },
    methods: {
      getGuild (resetT) {
        if (resetT) this.showActionAdd = false
        this.$parent.getGuild()
      },
      vselectfixer (dropdownList, component, { width }) {
        dropdownList.style.width = width
        const popper = createPopper(component.$refs.toggle, dropdownList, {
          placement: this.SelectPlacement,
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -1]
              }
            },
            {
              name: 'toggleClass',
              enabled: true,
              phase: 'write',
              fn ({ state }) {
                component.$el.classList.toggle('drop-up', state.SelectPlacement === 'top')
              }
            }
          ]
        })
        return () => popper.destroy()
      },
      undbindELTarJquery (el) {
        this.$(el.target).unbind()
      },
      isJson (str) {
        try {
          JSON.parse(str)
        } catch (e) {
          return false
        }
        return true
      },
      deleteAction (el, props, id, action) {
        el = this.$(el.path[4]).addClass('is-disabled')
        console.log(el, id, action, props)
        this.$store.dispatch('deleteApi', { path: `guild/${this.$route.params.id}/action/${action.id}`, body: { id: id, action: action.id }, headers: {}, params: {} }).then(async (result) => {
          this.$buefy.snackbar.open({
            message: this.actionType(action.actionType) + ' Deleted!',
            type: 'is-success',
            position: 'is-bottom-right'
          })
          this.guild.guildDB.data.customActions = this.guild.guildDB.data.customActions.filter(g => g.id !== id)
          await this.$store.dispatch('sleep', 2000)
          this.getGuild()
        }).catch(async (error) => {
          console.log(error, 'Error')
          this.$buefy.snackbar.open({
            message: 'Oh no! Some error happened!',
            type: 'is-danger',
            position: 'is-bottom-right'
          })
          el.removeClass('is-disabled')
          await this.$store.dispatch('sleep', 2000)
          this.getGuild()
        })
      },
      disableAction (el, id, action) {
        var _this = this
        el = this.$(el.target)
        el.prop('disabled', true)
        this.$store.dispatch('patchApi', { path: `guild/${this.$route.params.id}/action/${action.id}`, body: { type: action.actionType, action: 'status', id: action.id, value: !action.active }, headers: {}, params: {} }).then(async (result) => {
          el.val(!action.active)
          this.$buefy.snackbar.open({
            message: 'Action was updated!',
            type: 'is-success',
            position: 'is-bottom-right'
          })
          el.prop('disabled', false)
          await _this.$store.dispatch('sleep', 2000)
          this.getGuild()
        }).catch(async (error) => {
          console.log(error, 'er')
          this.$buefy.snackbar.open({
            message: 'Oh no! Some error happened!',
            type: 'is-danger',
            position: 'is-bottom-right'
          })
          el.prop('disabled', false)
          await this.$store.dispatch('sleep', 2000)
          this.getGuild()
        })
      },
      updateInput (el, type, id, action) {
        var _this = this
        el = this.$(el.target)
        el.data('valbefore', el.val())
        el.donetyping(function (callback) {
          var value = el.val()
          if (el.data('valbefore') === value) return
          el.prop('disabled', true)
          _this.$store.dispatch('patchApi', { path: `guild/${_this.$route.params.id}/action/${action.id}`, body: { type: action.actionType, action: type, id: action.id, value: value }, headers: {}, params: {} }).then(async (result) => {
            el.val(value)
            _this.$buefy.snackbar.open({
              message: 'Action was updated!',
              type: 'is-success',
              position: 'is-bottom-right'
            })
            el.prop('disabled', false)
            await _this.$store.dispatch('sleep', 2000)
            _this.getGuild()
          }).catch(async (error) => {
            console.log(error, 'er')
            el.val(el.data('valbefore'))
            _this.$buefy.snackbar.open({
              message: 'Oh no! Some error happened!',
              type: 'is-danger',
              position: 'is-bottom-right'
            })
            el.prop('disabled', false)
            await _this.$store.dispatch('sleep', 2000)
            _this.getGuild()
          })
        })
      },
      updateInputLy (value, type, id, action) {
        // if (el.data('valbefore') === value) return
        this.$store.dispatch('patchApi', { path: `guild/${this.$route.params.id}/action/${action.id}`, body: { type: action.actionType, action: type, id: action.id, value: value }, headers: {}, params: {} }).then(async (result) => {
          this.$buefy.snackbar.open({
            message: 'Action was updated!',
            type: 'is-success',
            position: 'is-bottom-right'
          })
          await this.$store.dispatch('sleep', 2000)
          this.getGuild()
        }).catch(async () => {
          this.$buefy.snackbar.open({
            message: 'Oh no! Some error happened!',
            type: 'is-danger',
            position: 'is-bottom-right'
          })
          await this.$store.dispatch('sleep', 2000)
          this.getGuild()
        })
      },
      actionType (type = null) {
        switch (type) {
        case 'tag':
          return 'Command Tag'
        case 'commandEmoji':
          return 'Command Role (OLD)'
        case 'commandRole':
          return 'Command Role'
        case 'reactionEmoji':
          return 'Reaction Role'
        case 'automessage':
          return 'Auto Message'
        default:
          return 'Unknown'
        }
      }
    }
  }
</script>

<style>
  .vs--disabled .vs__dropdown-toggle, .vs--disabled .vs__clear, .vs--disabled .vs__search, .vs--disabled .vs__selected, .vs--disabled .vs__open-indicator {
    background: var(--main-color-bg-2);
    cursor: not-allowed;
    color: #7a7a7a !important;
  }
  .vs__dropdown-toggle {
    background: var(--main-color-bg-2);
    color: white !important;
    border-color: var(--main-color-bg-3) !important;
    min-height: 2.25em !important;
    height: 100% !important;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: 0 0.1rem 1.1rem rgba(0,0,0,.3) !important;
  }
  .vs__dropdown-menu {
    background: var(--main-color-bg-1);
    color: white;
    border-color: var(--main-color-bg-3) !important;
    border-top-style: unset !important;
    border-radius: 4px 4px 4px 4px !important;
  }
  .vs__open-indicator {
    fill: #6045aa !important;
  }
  .vs__open-indicator:hover {
    fill: #7957d5 !important;
  }
  .vs__clear {
    fill: darkred !important;
  }
  .vs__clear:hover {
    fill: red !important;
  }
  .vs--single .vs__selected {
    color: white;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    overflow: hidden !important;
  }
  .vs__dropdown-option--selected {
    background-color: var(--main-color-bg-2);
  }
  .vs__dropdown-option--highlight {
    background: var(--main-color-bg-3) !important;
  }
  .vs_actions {
    cursor: pointer;
  }
  .vs__selected {
    background-color: var(--main-color-bg-1);
    color: white;
  }
  .vs__deselect {
    fill: darkred;
  }
  .vs__deselect:hover {
    fill: red;
  }
</style>

<style>
  .labelcolor-warning > .label {
    color: #ffdd57 !important
  }
  .help {
    color: white !important;
  }
  .hovermessagefeild:hover > .help {
    display: unset !important;
  }
  .hovermessagefeild > .help {
    display: none !important;
  }
  .is-invisible {
    display: none !important;
  }
  .has-shadow {
    box-shadow: 0 0.1rem 1.1rem rgba(0,0,0,.3)!important
  }
</style>

<style lang="scss" scoped>

@media only screen and (max-width: 768px) {
  #action-status-title-tag {
    display: none !important;
  }
  #action-status-title-tag-2 {
    display: inherit !important;
  }
  .action {
    box-shadow: 0 0.1rem 1.1rem rgba(0,0,0,.3)!important
  }
  .card-header-icon {
    display: none;
  }
  .hovermessagefeild > .help {
    display: unset !important;
  }
}

.button.is-fullwidth {
  box-shadow: 0 0.1rem 1.1rem rgba(0,0,0,.3)!important;
}
.columns {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.action-column {
  margin-bottom: 10px;
  padding: 5px;
  padding-bottom: 0px;
}

.action {
  border-radius: 1px;
  border-color: transparent;
  border-width: 1px;
  border-radius: 10px;
  border-style: solid;
  background: var(--main-color-bg-1);
  color: #fff !important;
}

.action-delete {
  float: right;
  // margin-top: 10px;
  margin-right: -10px;
  visibility: hidden;
}

.action:hover .action-delete {
  visibility: visible !important;
}

.modal-close:hover::before, .delete:hover::before, .modal-close:hover::after, .delete:hover::after {
  background-color: #f04747 !important;
}

// .action-header {}

.action-header-title {
  color: #fff !important;
}

// .card {
//   border-radius: 6px;
//   border: 1px solid var(--main-color-bg-3);
//   background: var(--main-color-bg);
//   height: unset !important;
// }
h3.subtitle.is-spaced {
  color: lightgray;
}
.p-1 {
  padding: 1em;
}
.sidebar-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    /* min-height: 100vh; */
    .sidebar-layout {
        display: flex;
        flex-direction: row;
        min-height: 100%;
        min-height: calc(100vh - ( 3.25rem - 0rem ) );
    }
}
</style>
