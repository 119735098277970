<template>
  <div>
    <b-notification style="margin-left: 2rem; margin-right: 2rem;margin-top: -20px;" type="is-warning" aria-close-label="Close notification" role="alert">
      This feature is in public beta, some functions may not work correctly.
    </b-notification>
    <div class="columns is-centered" style="padding-top: 0.5rem !important;">
      <div class="column is-centered">
        <h3 v-if="this.loading" class="title is-3" style="text-align: center;">Loading Audits!</h3>
        <h3 v-else-if="this.audit.length <= 0" class="title is-3" style="text-align: center;">No Audits Found!</h3>
        <h3 v-if="this.audit.length <= 0 && !this.loading" class="subtitle is-6 has-text-white" @click="getAudit()" style="text-align: center;cursor: pointer;">Click to refresh!</h3>
        <div class="container" v-if="this.audit.length > 0" :class="{'is-disabled-noCheck': this.loading }">
          <div class="columns is-mobile is-centered is-multiline">
            <div class="column action-column has-text-centered is-full" v-for="audit in this.audit" :id="audit.timestamp" :key="audit.timestamp">
              <b-collapse animation="slide" :open="false" class="card action" :aria-id="'aria' + audit.timestamp">
                <div slot="trigger" slot-scope="props" :aria-controls="'aria' + audit.timestamp" class="card-header action-header">
                  <p class="card-header-title action-header-title">
                      <b-tag id="action-status-title-tag" v-if="audit.action.debug" ellipsis style="margin-right: 5px" type="is-danger">Debug</b-tag>
                      <b-tag id="action-status-title-tag" ellipsis type="is-info">{{ audit.action.loc }}</b-tag>
                    <!-- <b-tag id="action-status-title-tag-2" style="display: none;" ellipsis :type="(action.active ? 'is-success' : 'is-danger')">{{(action.active ? 'E' : 'D')}}</b-tag> -->
                    <span style="padding-left: 10px; margin-bottom:0px;" class="title is-5 has-text-white Nunito">{{ audit.action.from }}</span>
                    <span id="action-status-title-tag" style="padding-left: 10px;font-size: 13px;margin-left: -3px;margin-bottom:-5px;" class="has-text-white Nunito">{{ $MomentJS(Number(audit.timestamp)).format("[Created at,] dddd, MMMM Do YYYY") }}</span>
                  </p>
                  <a class="card-header-icon">
                    <b-icon :icon="props.open ? 'caret-up' : 'caret-down'"> </b-icon>
                    <!-- <button class="delete action-delete" @click.stop="deleteAction($event, props, action.id, action)"></button> -->
                  </a>
                </div>
                <div class="card-content">
                  <div class="content">
                    <div class="columns is-multiline">
                      <div class="column is-centered is-half" v-if="audit.user">
                        <b-field class="hovermessagefeild" label="Event Action By">
                          <router-link style="align-items: center; justify-content: center; display: flex;" tag="div" :to="{ path: '/profile/' + audit.user.id}">
                            <img :src="'https://cdn.discordapp.com/avatars/' + audit.user.id + '/' + audit.user.avatar + '.webp?size=128'" onerror="this.src='https://cdn.discordapp.com/embed/avatars/1.png?size=128';" class="icon-img has-shadow" style="max-height: 36px; margin-right: 5px; cursor: pointer;">
                            <p style="cursor: pointer;">{{ audit.user.username }}#{{ audit.user.discriminator }}</p>
                          </router-link>
                        </b-field>
                      </div>
                      <div class="column is-centered is-half">
                        <b-field class="hovermessagefeild" label="Data">
                          <b-input custom-class="input-default" disabled :value="audit.data ? JSON.stringify(audit.data) : 'No Data Found For Event.'" type="textarea"></b-input>
                        </b-field>
                      </div>
                      <!-- <div class="column is-centered is-half">
                        <b-field class="hovermessagefeild" label="Event Action By">
                        </b-field>
                      </div> -->
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns is-centered is-mobile">
      <div class="column is-full has-text-centered" :class="{'is-disabled-noCheck': this.loading }">
        <b-pagination style="margin-bottom: 0px;"
            :total="totalAmount"
            :range-before="3"
            :range-after="1"
            :per-page="perPage"
            v-model="page"
            :icon-prev="'chevron-left'"
            :icon-next="'chevron-right'"
            :order="'is-centered'"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
            @change="updateAudit">
        </b-pagination>
        <h3 class="subtitle is-6 has-text-white" @click="getAudit()" style="text-align: left;cursor: pointer;margin-bottom: 1.5rem;">Click to refresh!</h3>
      </div>
    </div>
  </div>
</template>

<script>
  import 'vue-select/dist/vue-select.css'
  // import JsColor from '@/components/JsColor.vue'
  // import vSelect from 'vue-select'
  import { createPopper } from '@popperjs/core'

  export default {
    name: 'audit',
    components: {
      // JsColor,
      // vSelect
    },
    props: ['guildData'],
    computed: {
      guild: function () {
        return this.guildData
      }
    },
    data () {
      return {
        SelectPlacement: 'bottom',
        page: 1,
        perPage: 10,
        totalAmount: 0,
        audit: [],
        loading: true
      }
    },
    created () {
      this.getAudit()
    },
    methods: {
      getGuild () {
        this.$parent.getGuild()
        this.page = 1
        this.totalAmount = 0
        this.audit = []
        this.getAudit()
      },
      undbindELTarJquery (el) {
        this.$(el.target).unbind()
      },
      vselectfixer (dropdownList, component, { width }) {
        dropdownList.style.width = width
        const popper = createPopper(component.$refs.toggle, dropdownList, {
          placement: this.SelectPlacement,
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -1]
              }
            },
            {
              name: 'toggleClass',
              enabled: true,
              phase: 'write',
              fn ({ state }) {
                component.$el.classList.toggle('drop-up', state.SelectPlacement === 'top')
              }
            }
          ]
        })
        return () => popper.destroy()
      },
      updateAudit () {
        return this.getAudit()
      },
      getAudit () {
        this.loading = true
        this.$store.dispatch('getApi', { path: `guild/${this.$route.params.id}/auditlog`, params: { page: this.page - 1 } }).then(async (result) => {
          console.log(result)
          this.totalAmount = result.data.totalAmount
          this.audit = result.data.results
          this.loading = false
        })
      },
      actionType (type) {
        return type
      }
    }
  }
</script>

<style>
  .vs--disabled .vs__dropdown-toggle, .vs--disabled .vs__clear, .vs--disabled .vs__search, .vs--disabled .vs__selected, .vs--disabled .vs__open-indicator {
    background: var(--main-color-bg-2);
    cursor: not-allowed;
    color: #7a7a7a !important;
  }
  .vs__dropdown-toggle {
    background: var(--main-color-bg-2);
    color: white !important;
    border-color: var(--main-color-bg-3) !important;
    min-height: 2.25em !important;
    height: 100% !important;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: 0 0.1rem 1.1rem rgba(0,0,0,.3) !important;
  }
  .vs__dropdown-menu {
    background: var(--main-color-bg-1);
    color: white;
    border-color: var(--main-color-bg-3) !important;
    border-top-style: unset !important;
    border-radius: 4px 4px 4px 4px !important;
  }
  .vs__open-indicator {
    fill: #6045aa !important;
  }
  .vs__open-indicator:hover {
    fill: #7957d5 !important;
  }
  .vs__clear {
    fill: darkred !important;
  }
  .vs__clear:hover {
    fill: red !important;
  }
  .vs--single .vs__selected {
    color: white;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    overflow: hidden !important;
  }
  .vs__dropdown-option--selected {
    background-color: var(--main-color-bg-2);
  }
  .vs__dropdown-option--highlight {
    background: var(--main-color-bg-3) !important;
  }
  .vs_actions {
    cursor: pointer;
  }
  .vs__selected {
    background-color: var(--main-color-bg-1);
    color: white;
  }
  .vs__deselect {
    fill: darkred;
  }
  .vs__deselect:hover {
    fill: red;
  }
</style>

<style>
  .labelcolor-warning > .label {
    color: #ffdd57 !important
  }
  .help {
    color: white !important;
  }
  .hovermessagefeild:hover > .help {
    display: unset !important;
  }
  .hovermessagefeild > .help {
    display: none !important;
  }
  .is-invisible {
    display: none !important;
  }
  .has-shadow {
    box-shadow: 0 0.1rem 1.1rem rgba(0,0,0,.3)!important
  }
</style>

<style lang="scss" scoped>
@media only screen and (max-width: 768px) {
  #action-status-title-tag {
    display: none !important;
  }
  #action-status-title-tag-2 {
    display: inherit !important;
  }
  .action {
    box-shadow: 0 0.1rem 1.1rem rgba(0,0,0,.3)!important
  }
  .card-header-icon {
    display: none;
  }
  .hovermessagefeild > .help {
    display: unset !important;
  }
}

.button.is-fullwidth {
  box-shadow: 0 0.1rem 1.1rem rgba(0,0,0,.3)!important;
}
.columns {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.action-column {
  margin-bottom: 10px;
  padding: 5px;
  padding-bottom: 0px;
}

.action {
  border-radius: 1px;
  border-color: transparent;
  border-width: 1px;
  border-radius: 10px;
  border-style: solid;
  background: var(--main-color-bg-1);
  color: #fff !important;
}

.action-delete {
  float: right;
  // margin-top: 10px;
  margin-right: -10px;
  visibility: hidden;
}

.action:hover .action-delete {
  visibility: visible !important;
}

.modal-close:hover::before, .delete:hover::before, .modal-close:hover::after, .delete:hover::after {
  background-color: #f04747 !important;
}

// .action-header {}

.action-header-title {
  color: #fff !important;
}
// .card {
//   border-radius: 6px;
//   border: 1px solid var(--main-color-bg-3);
//   background: var(--main-color-bg);
//   height: unset !important;
// }
h3.subtitle.is-spaced {
  color: lightgray;
}
.p-1 {
  padding: 1em;
}
.sidebar-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    /* min-height: 100vh; */
    .sidebar-layout {
        display: flex;
        flex-direction: row;
        min-height: 100%;
        min-height: calc(100vh - ( 3.25rem - 0rem ) );
    }
}

.icon-img {
    /* float: left; */
    border-radius: 100px;
    border-color: var(--main-color-bg-3);
    border-width: 1px;
    border-radius: 100px;
    border-style: solid;
    background-color: var(--main-color-bg) !important;
}

</style>
